<template>
<div class="position-relative overflow-hidden" dir="ltr">
    <head>
      <meta name="robots" content="noindex, nofollow" />
    </head>
    <AppNavbar/>
    <div>
      <img
        alt=""
        class="background-image"
        src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fcircles-background.png?alt=media&token=d420088e-66c6-4c95-bd3b-2f218dd36aba">
    </div>
    <ImageBanner :backgroundImage= "'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fride-info-banner.jpg?alt=media&token=b765e21f-2604-4874-a029-3453357e4897'"
                 dir="rtl"
                 paragraph="התחברו או הירשמו כדי לצפות במסלול האישי שלכם."
                 size="240px" title="המסלול האישי שלך"/>
  <b-container>
    <b-row class="justify-content-center">
      <b-col col md="6">
        <b-form>
          <b-form-group
            id="label-title"
            class="text-right"
            label-for="title"
            dir="rtl"
          >
            <template #label>
              <p>ברוכים הבאים למסלול האישי שלכם!</p>
              <p v-if ="!isAppLoggedIn">כדי להיכנס ולצפות בפרטי המסלול, אנא התחברו או הירשמו.</p>
              <p>לאחר ההתחברות, לחצו על 'מעבר למסלול' כדי להגיע לעמוד המסלול שלכם, שבו תמצאו את כל המידע שתכננו במיוחד עבורכם.</p>
            </template>
          </b-form-group>
        </b-form>
        <div  v-if ="this.isAppLoggedIn" >
          <PrimaryButton 
            v-if ="context === 'consulting' && rideKeyInformation && rideKeyInformation.consulting_meeting_calendar_link"
            class="w-100 mb-5 greenToBlue"
            @click="goToRideManagerColender()"
          >
            לקביעת פגישה נוחה להתאמת המסלול לצרכים שלי
          </PrimaryButton>

          <PrimaryButton 
            class="w-100 mb-5"
            @click="copyLink"
          >
            העתקת הלינק של המסלול לשימוש עתידי
          </PrimaryButton>

          <PrimaryButton 
            class="w-100 mb-5"
            @click="goToRide"
          >
            מעבר למסלול 
          </PrimaryButton>
        </div>
        <PrimaryButton
          v-else
          @click="this.openLoginForm"
          class="w-100 mb-5"
        >
          להרשמה או התחברות
        </PrimaryButton>
      </b-col>
    </b-row>
  </b-container>
  <AppFooter
    v-if="!isSubdomain"
  />
</div>
</template>

<script>
import ImageBanner from "@/components/ImageBanner/ImageBanner.vue";
import PrimaryButton from "@/components/Buttons/PrimaryButton.vue";
import AppNavbar from "@/components/Template/AppNavbar.vue";
import AppFooter from "@/components/Template/AppFooter.vue";
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'AssignToRide',
  components: {
    AppFooter,
    PrimaryButton,
    ImageBanner,
    AppNavbar,
    },
  computed: { ...mapGetters([  'isAppLoggedIn',
                              'userId',
                              'rideKeyInformation',
                              'rideKeyValue',
                              'rideKeyNewRideIdAtCopyRide',
                            ]),
              ...mapGetters("theme",  [ "isSubdomain",
                                      ]),
    },
    methods:
    {

    ...mapActions([ 'openLoginForm',
                    'showModal',
                    'fetchRide',
                    'assignUserToLockedRide',
                    'getAssignedRideKeyInformation',
                    'resetAllRideKeyData',
                    'showModalWithParameters',
                    ] ),
    copyLink(){
      this.showModal( 'copyPrivateLink' )
    },
    goToRide() {
      const rideId = this.rideKeyNewRideIdAtCopyRide ?? this.rideKeyInformation?.ride_id ?? null;
      if(rideId){
        this.$router.push('/single_ride_with_map/'+ rideId)
      }
      else{
        console.log("ride ID is not defined")
      }
    },
    goToRideManagerColender() {
      if ( this.rideKeyInformation.consulting_meeting_calendar_link ){
        window.open(
          this.rideKeyInformation.consulting_meeting_calendar_link,
          '_blank' // Opens in a new tab
        );
      }
    },
    validateUserAccessToRideAndTryToAssign(){
      if ( this.isAppLoggedIn && this.rideKeyInformation?.ride_id) {
          this.assignUserToLockedRide( this.rideKeyValue).then(() => {
            const rideId = this.rideKeyNewRideIdAtCopyRide ?? this.rideKeyInformation?.ride_id ?? null;
            this.$store.commit("setLowedRideId", rideId);
            this.fetchRide();
          });
        }
    },
  },
  props: ['context'],
  watch: {
    isAppLoggedIn(newVal) {
        if (newVal) {
          this.validateUserAccessToRideAndTryToAssign()        }
      },
    rideKeyInformation(newVal) {
      if (newVal?.ride_id) {
        this.validateUserAccessToRideAndTryToAssign()
      }
      else{
        this.showModalWithParameters({
          errorNumber: 'the_private_link_is_not_valide',
          type: 'textConverter',
        });
      }
    },
  },
  mounted() {
    var rideKey = this.$route.params.ride_key;
    if(!rideKey){
      const savedRideKey = sessionStorage.getItem('selectedRideKey');
      if (savedRideKey) {
        rideKey = savedRideKey
      }
    }
    if(rideKey){
      this.$store.commit("setRideKeyValue", rideKey);
      this.getAssignedRideKeyInformation( rideKey);
    }
    window.history.replaceState({}, '', `/assign_to_ride`);
    this.validateUserAccessToRideAndTryToAssign()
  },
};
</script>


<style scoped>
  .greenToBlue{
    background: linear-gradient(45deg, #34D100, #07A0C3);
  }
</style>