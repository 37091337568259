<template>
  <div>
    <AppNavbar/>
    <div class="attraction-table-container" dir="rtl">
      <table class="attraction-table">
        <thead>
          <tr>
            <th>שם</th>
            <th>תיאור</th>
            <th>כולל תמונה</th>
            <th>עריכה</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in rideManagerAttractions" :key="index">
            <td>{{ item.title }}</td>
            <td>{{ truncateText(item.text) }}</td>
            <td>{{ item.image ? '✅' : '❌' }}</td>
            <td>
              <button class="edit-button" @click="cleanHistoryAndEditAttraction(item.id)">ערוך</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <AppFooter/>
  </div>
</template>

<script>
import AppNavbar from "@/components/Template/AppNavbar.vue";
import AppFooter from "@/components/Template/AppFooter.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: 'RideManagerAttractions',
  components: {
    AppFooter,
    AppNavbar,
  },
  computed: {
              ...mapGetters([
                              "rideManagerAttractions",
                            ]),
  },

  methods: {
    ...mapActions([
      "cleanHistoryAndEditAttraction",
      "validateAndupdateAppToken",
      "fetchRideMangerAttractions",
    ]),
    truncateText(text) {
      return text.length > 60 ? text.slice(0, 60) + '...' : text;
    },
  },
  created() {
    this.validateAndupdateAppToken();
    this.fetchRideMangerAttractions();
  },

}
</script>

<style scoped>
.attraction-table-container {
  padding: 80px 30px;
  background: linear-gradient(45deg, #E0BE36, #DD6E42);
  border-radius: 10px;
  direction: rtl;
  margin: 40px auto;
  max-width: 1000px;
  box-sizing: border-box;
}

/* Table layout */
.attraction-table {
  width: 100%;
  border-collapse: collapse;
  background: white;
  border-radius: 8px;
  overflow: hidden;
  direction: rtl;
  table-layout: auto; /* ✅ auto-layout allows text to wrap */
}

/* Table headers and cells */
.attraction-table th,
.attraction-table td {
  padding: 12px 15px;
  text-align: right;
  border-bottom: 1px solid #ddd;
  font-size: 16px;
  word-wrap: break-word;
  white-space: normal; /* ✅ text wraps inside cells */
}

/* Header styling */
.attraction-table th {
  background: linear-gradient(45deg, #34D0B6, #07A0C3);
  color: white;
  white-space: nowrap;
}

/* Edit button */
.edit-button {
  background: linear-gradient(45deg, #34D0B6, #07A0C3);
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  white-space: nowrap;
}

.edit-button:hover {
  opacity: 0.85;
}

/* 🌟 Mobile responsiveness */
@media (max-width: 600px) {
  .attraction-table-container {
    padding: 20px 10px;
    margin: 20px 10px;
  }

  .edit-button {
    padding: 5px 10px;
    font-size: 13px;
  }

  .attraction-table th,
  .attraction-table td {
    padding: 8px 10px;
    font-size: 14px;
  }

  .attraction-table {
    font-size: 13px;
  }
}
</style>
