<template>
  <div>
    <b-modal
      id="doYouWantToAddThisPointToRide"
      modal-class="modal-cover"
      v-model="isVisible"
      hide-backdrop
      hide-footer
      content-class="shadow-lg"
    >
      <section class="text-center">
        <b-row>
          <b-col cols="12" class="py-2">
            <h3 class="font-weight-medium card-title">
              שמירת הלינק לטיול שלך.  
            </h3>
          </b-col>

          <b-col cols="12" class="py-2">
            <p>
              ברכות! נרשמת בהצלחה לצפייה בטיול שלך.
            </p>
            <p>
              חשוב לשמור את הלינק לטיול כדי שתוכל לחזור אליו בקלות בעתיד.
            </p>
          </b-col>
          <b-col cols="12" class="py-2">
            <h4 
              v-if="allRide && allRide.ride && allRide.ride.id"
              class="text-wrap link-container"
            > 
              {{getLinkUrl()}}
            </h4>
            <b-button v-if="allRide && allRide.ride && allRide.ride.id" @click="copyLink" variant="primary" class="mt-2">
              העתק קישור
            </b-button>
          </b-col>
          <b-col cols="12" class="py-2">
            <ShareNetwork
              network="whatsApp"
              :url=getLinkUrl()
              :title=getTexts()[2].title
              :description=getTexts()[2].description
              >
              <b-img
                :src="require('@/assets/images/icons/icons8-whatsapp-50.svg')"
                alt="Image"
              >
              </b-img>
            </ShareNetwork>  
          </b-col>
        </b-row>
      </section>
    </b-modal>
  </div>
</template>





<script>
import { mapGetters } from "vuex";

export default {
props: ["value"],
  data(){return{
    shareRideNetworkingText: [
          
          {
            type: 'header',
            text: "שמירת הלינק לטיול שלך"

          },
          {
            type: 'text',
            text: "ברכות! נרשמת בהצלחה לצפייה בטיול שלך. חשוב לשמור את הלינק לטיול כדי שתוכל לחזור אליו בקלות בעתיד."

          },
          {
            network: "whatsApp",
            title: "שתף טיול",
            description: "היי! אני משתף איתך את הלינק לצפייה בטיול שלי. שמור אותו לגישה מהירה:",          },
        ],
    shareAtractionNetworkingText: [
          
          {
            type: 'header',
            text: "שליחת הפעילות לחברים"

          },
          {
            type: 'text',
            text: "האדם הצופה בטיול זה לא יוכל לערוך או לשנות את הטיול שלכם,\
            הוא יוכל רק לצפות בטיול ואים הוא ממש יאהב הוא יוכל להעתיק את הטיול ולערוך אחד אחר עבורו"

          },
          {
            network: "whatsApp",
            title: "בנית טיול משפחתי.",
            description: "יש כאן בסיס לטיול שניתן להתאים לרצונות שלנו",
          },
        ],
  }
  },

  computed: {
    ...mapGetters([ 'allRide',
                    'inerModalParameters',
                     ]),
    ...mapGetters("theme",  [ "getSubdomain",
                              "isSubdomain",
                            ]),

    isVisible: {
      set(val) {
        this.$emit("input", val);
      },
      get() {
        return this.value;
      },
    },
  },
  methods:{
  copyLink() {
    const link = this.getLinkUrl();
    navigator.clipboard
      .writeText(link)
      .then(() => {
        this.$bvToast.toast('הקישור הועתק בהצלחה!', {
          title: 'העתקת קישור',
          variant: 'success',
          solid: true,
        });
      })
      .catch((err) => {
        console.error('Failed to copy link: ', err);
        this.$bvToast.toast('שגיאה בהעתקת הקישור', {
          title: 'שגיאה',
          variant: 'danger',
          solid: true,
        });
      });
    },
    getLinkUrl(){
      var subDomain = '';
      if( this.isSubdomain){
        subDomain = this.getSubdomain + "."
      }
      if(this.inerModalParameters){
        if ( this.allRide && this.allRide.ride && this.allRide.ride.id ){
          return ( "https://" + subDomain +  "tripplanner.co.il/single_ride_with_map_attraction/" + this.allRide.ride.id + "/" + this.inerModalParameters.attractionId )
        }
        return "https://" + subDomain +  "tripplanner.co.il"
      }
      else{
        if ( this.allRide && this.allRide.ride && this.allRide.ride.id ){
          return ( "https://" + subDomain +  "tripplanner.co.il/single_ride_with_map/" + this.allRide.ride.id )
        }
        return "https://" + subDomain +  "tripplanner.co.il"
      }
    },
    getTexts(){
      if( this.inerModalParameters) {
        return this.shareAtractionNetworkingText
        }
      else{
        return this.shareRideNetworkingText
      }
    },

  },

};
</script>


<style scoped>
.modal.modal-cover .modal-content,
.modal.modal-cover .modal-footer,
.modal.modal-cover .modal-header {
  border: none;
}
.height-50 {
  height: 50px !important;
}
.width-50 {
  width: 50px !important;
}


.pb-3, .py-3 {
    padding-bottom: 1rem !important;
}

.pt-3, .py-3 {
    padding-top: 1rem !important;
}

.rounded-pill {
    border-radius: 50rem !important;
}

.btn-warning {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
}

.btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

.btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

h5, .h5 {
    font-size: 1.25rem;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}

button, [type="button"], [type="reset"], [type="submit"] {
    -webkit-appearance: button;
}

button, select {
    text-transform: none;
}

button, input {
    overflow: visible;
}
input, button, select, optgroup, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
button {
    border-radius: 0;
}
*, *::before, *::after {
    box-sizing: border-box;
}
user agent stylesheet
button {
    appearance: auto;
    text-rendering: auto;
    color: buttontext;
    letter-spacing: normal;
    word-spacing: normal;
    line-height: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: center;
    align-items: flex-start;
    cursor: default;
    box-sizing: border-box;
    background-color: buttonface;
    margin: 0em;
    padding-block: 1px;
    padding-inline: 6px;
    border-width: 2px;
    border-style: outset;
    border-color: buttonborder;
    border-image: initial;
}
.text-center {
    text-align: center !important;
}
*, *::before, *::after {
    box-sizing: border-box;
}
user agent stylesheet
div {
    display: block;
    unicode-bidi: isolate;
}
.btn-warning {
    background: linear-gradient(45deg, #E0BE36, #DD6E42);
    color: white;
    border: 0;
}

.btn-warning:hover {
    background: linear-gradient(45deg, #e8c538, #ee7748);
    color: white;
    border: 0;
}
.btn-primary {
    background: linear-gradient(45deg, #34D0B6, #07A0C3);
    color: white;
    border: 0;
}

.btn-primary:hover {
    background: linear-gradient(45deg, #39e1c5, #08add3);
    color: white;
    border: 0;
}
.link-container {
  word-wrap: break-word; /* Break long words to avoid overflow */
  overflow-wrap: break-word; /* Ensure text wraps within the container */
  max-width: 100%; /* Prevent the text from exceeding the width of its parent */
  margin: 0 auto; /* Center align the text container */
  padding: 10px; /* Optional: Add padding for a better look */
  background-color: #f8f9fa; /* Optional: Add background to highlight the link */
  border-radius: 4px; /* Optional: Round corners */
  overflow: hidden; /* Hide any overflow */
  text-align: center; /* Center the text */
}

</style>