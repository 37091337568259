<template>
  <div class="position-relative overflow-hidden" dir="ltr">
    <AppNavbar/>
    <div>
      <img alt="" class="background-image" src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fcircles-background.png?alt=media&token=493f0ef0-6c28-4446-b093-b978b6b722fc">
    </div>
    <div class="accommodations">
      <ImageBanner  :backgroundImage="'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2Ftop-welcome.jpg?alt=media&token=35e8a7d6-512f-49e2-a1a3-861044d2fe38'"
                    paragraph=""
                    size="100%" title="מצא את החופשה המושלמת">
        <div class="d-flex align-items-center flex-column mt-4">
          <div class="input-icon d-flex align-items-center">
            <i class="fa-solid fa-plane plane-icon"></i>
            <gmap-autocomplete
              @place_changed="searchRidesByGeoPoint"
              :select-first-on-enter="true"
              v-on:input="handleInput"
              class="form-control text-right py-4 h5 rounded-pill border-0 bg-white shadow w-100"
              placeholder="?לאן טסים"
              :options="{
                fields: ['geometry'],
              }"
            >
            </gmap-autocomplete> 
          </div>
          <div class="d-flex input-icon justify-content-end">
            <b-form-group
                id="label-details"
                class="text-right justify-content-right d-flex align-items-center mb-0"
                label="מתאים לאנשים עם מוגבלות תנועה"
                label-for="checkbox-1">

              <b-form-checkbox
                  id="checkbox-1"
                  class="checkbox-right ml-2 checkbox-custom mb-2"
                  name="checkbox-1"
                  size="lg"
                  v-model="physicalDisabilities"
                  unchecked-value=false
                  value=true>
              </b-form-checkbox>
            </b-form-group>
          </div>
        </div>
      </ImageBanner>
      <!--      results-->
      <!-- <div class="container pt-md-3 pb-md-3">
        <div class="d-md-flex justify-content-end align-items-center d-none pb-2">
            <span class="h5 mr-2 mr-md-3">
                The results for "$set location" are
            </span>
          <span class="btn-primary btn rounded-pill py-2 px-3 h6">3 of 12,441</span>
        </div>
        <div class="d-block d-md-none mb-2">
          <p class="h5 d-block text-center mr-2 mr-md-3">
            The results for "$set location" are
          </p>
          <p class="mb-0 text-center">
            <span class="btn-primary btn rounded-pill py-2 px-3 h6">3 of 12,441</span>
          </p>
        </div>
      </div> -->
      <div class="container">
        <!--      filters-->      
        <!-- <div class="card border-0 shadow-sm p-md-2 rounded-5 mb-3">
          <div class="card-body d-flex flex-column text-right position-relative">
            <div class="d-flex align-items-center justify-content-end">
              <h5 class="mb-0 mr-3 text-right">Adjust the answers according to your preferences</h5>
              <b-button
                  v-b-toggle.collapse-1
                  class="text-decoration-none d-flex align-items-center"
                  variant="link"
                  @click="toggleArrow"
              >
                <span :class="['arrow', { rotated: isCollapsed }]"></span>

              </b-button>

            </div>
            <b-collapse id="collapse-1" class="mt-3" @hide="isCollapsed = false" @show="isCollapsed = true">
              <b-row class="mt-2">
                <b-col col lg="6" md="12" sm="12" xs="12">
                  <b-row class="">
                    <b-col class="pr-md-0" md="4" sm="12" xs="12">
                      <b-form-group
                          id="input-group-1"
                          class="text-right w-100 mr-2"
                          label="End date"
                          label-for="input-1"
                      >
                        <b-form-datepicker
                            id="example-datepicker"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            class="form-control border-primary rounded-2 text-right py-1 d-flex align-items-center"
                            placeholder="Check-out"
                            style="direction: rtl;"
                        ></b-form-datepicker>
                      </b-form-group>
                    </b-col>
                    <b-col class="pr-md-0" md="4" sm="12" xs="12">
                      <b-form-group
                          id="input-group-1"
                          class="text-right w-100 mr-2"
                          label="Start date"
                          label-for="input-1"
                      >
                        <b-form-datepicker
                            id="example-datepicker"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            class="form-control border-primary rounded-2 text-right py-1 d-flex align-items-center"
                            placeholder="Check-out"
                            style="direction: rtl;"
                        ></b-form-datepicker>
                      </b-form-group>
                    </b-col>
                    <b-col class="pr-md-0" md="4" sm="12" xs="12">
                      <b-form-group
                          id="label-age"
                          class="text-right w-100"
                          label="Age of participants"
                          label-for="age">
                        <DropdownWithSelect 
                          :col-size=12 placeholder="Select Age"></DropdownWithSelect>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col class="" lg="6" md="12" sm="12" xs="12">
                  <b-row>
                    <b-col class="pr-md-0" md="6" sm="12" xs="12">
                      <b-form-group
                          id="label-age"
                          class="text-right w-100 mr-2"
                          label="Season"
                          label-for="age">
                        <DropdownFilter></DropdownFilter>
                      </b-form-group>
                    </b-col>
                    <b-col md="6" sm="12" xs="12">
                      <b-form-group
                          id="label-age"
                          class="text-right w-100"
                          label="Special Periods"
                          label-for="age">
                        <DropdownFilter></DropdownFilter>
                      </b-form-group>
                    </b-col>
                  </b-row>

                </b-col>
              </b-row>
              <div class="d-flex align-items-center justify-content-end">
                <button
                    class="btn btn-secondary mr-2 mr-md-3 w-100 custom-width px-md-5 py-3 rounded-pill fw-semibold h5 mb-0 z-2">
                  Reset Filters
                </button>
                <button class="btn btn-primary px-md-5 w-100 custom-width py-3 rounded-pill fw-semibold h5 mb-0 z-2">
                  Apply Filters
                </button>
              </div>
            </b-collapse>
          </div>
        </div> -->

        <!--        results-->
        <div 
          class="mb-5"
          v-if="globalRides.length"
        >
          <ResultOfSearchCard
              v-for="(globalRide, index ) in globalRides"
              :key="index"
              :id="'index'"
              :ride='globalRide'
              :goToRideCallBack="goToRide"
            >
          </ResultOfSearchCard>
        </div>
        <div 
          v-else
          class="card border-0 shadow-sm rounded-5 p-2 p-md-4 p-lg-5">
          <div class="card-body d-flex justify-content-center flex-column align-items-center">
            <img :src="'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2Fno-trips-found.svg?alt=media&token=00584132-fca1-4b1f-9a62-8bc5d702597c'" alt="" class="img-fluid" height="200">
            <p class="mb-0 fs-5 text-muted text-center mt-3">
              מצטערים, כרגע אין לנו חופשות שמשפחות שיתפו למיקום זה. 
              נשמח אם תוכלו לעזור להעשיר את מאגר החופשות המשותפות ולהוסיף את החופשה לאתר עם חזרתכם לארץ.
            </p>
          </div>
        </div>
      </div>
    </div>
    <HowItWorksNewNewCss></HowItWorksNewNewCss>
    <AppFooter/>
  </div>
</template>

<script>

import AppNavbar from "@/components/Template/AppNavbar.vue";
import AppFooter from "@/components/Template/AppFooter.vue";
import ImageBanner from "@/components/ImageBanner/ImageBanner.vue";
// import DropdownFilter from "@/components/Buttons/DropdownFilter.vue";
import ResultOfSearchCard from "@/components/Cards/ResultOfSearchCard.vue";
import HowItWorksNewNewCss from "@/components/HowItWorksNewNewCss"
// import DropdownWithSelect from "@/components/Buttons/DropdownWithSelect.vue";

import config from '@/common/config';
import { mapActions, mapGetters } from 'vuex';


export default {
  name: "SearchRide",
  data() {
    return {
      isCollapsed: false, // Tracks the state of the collapse
    };
  },
  components: {
    // DropdownWithSelect,
    HowItWorksNewNewCss,
    ResultOfSearchCard,
    // DropdownFilter,
    ImageBanner,
    AppFooter,
    AppNavbar,
  },
  computed:{ ...mapGetters(['globalRides',
                            'userId',
                            'globalRidesSearchRidePhysicalDisabilities',
                            ]),
    physicalDisabilities:{
      get () {
        return this.globalRidesSearchRidePhysicalDisabilities
      },
      set (value) {
        this.$store.commit('SetGlobalRidesSearchRidePhysicalDisabilities', value)
      }
    },
  },
  methods: {
    ...mapActions([ 'fetchGlobalRidesByName',
                    'searchRidesByGeoPoint',
                    ]),
    handleInput(event) {
      this.userSearchInput = event.target.value
    },
    goToSearchRideByName(){
      if(this.userSearchInput == ""){
        this.goToSerchattractionPage();
        return
      }
      const link = '/search_ride_by_name/' + this.userSearchInput
      this.$router.push(link)
    },

    toggleArrow() {
      this.isCollapsed = !this.isCollapsed;
    },
    loadErrorImage( event, URL ){
      event.target.src = URL
    },
    carouselId( index ){
      // console.log( "carousel-" + index.toString() )
      return "carousel-" + index.toString()
    },
    participent( globalRide ){
      var text = "" ;
      if( globalRide.number_of_child )
        text = "ילדים"
      if( ( ( globalRide.number_of_adult ) || ( globalRide.number_of_teen ) ) & ( globalRide.number_of_child ) )
        text = " ו" + text
      if( globalRide.number_of_teen )
        text = "נערים" + text
      if( ( globalRide.number_of_adult ) & ( globalRide.number_of_teen ) & ( globalRide.number_of_child ) )
        text = "," + text
      if( ( globalRide.number_of_adult ) & ( globalRide.number_of_teen ) & ( globalRide.number_of_child == 0 ) )
        text = " ו" + text
      if( globalRide.number_of_adult )
        text = "בוגרים" + text
      return text
    },
    goToRide( rideId ) {
      this.$router.push('/single_ride_with_map/'+ rideId)
    },
  },
  created(){
    const location_name = this.$route.params.location_name
    const urlName = this.$route.name;
    if( config.config.ROUTES_URL_NAMES.searchRideByName == urlName && location_name ){
      this.fetchGlobalRidesByName( location_name );      
    }
  },
  metaInfo() {
    return {
      title: 'TripPlanner',
      link: [
        { property: 'og:title', content: 'TripPlanner' },
        { property: 'og:description', content: 'ניתן למצוא מסלולים שמטיילים אחרים שיתפו' },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `https://tripplanner.co.il/search_ride` },
        // { property: 'og:image', content: 'https://tripplanner.co.il/image-url.jpg' },
        { name: 'description', content: 'חיפוש מסלולי טיול' },
        // { name: 'keywords', content: 'keyword1, keyword2, keyword3' },
        { rel: 'canonical', href: "https://tripplanner.co.il/search_ride" }
      ]
    };
  }
};
</script>

<style scoped>
.form-control {
  height: 50px !important;
}

.input-icon {
  position: relative;
  width: 60%;
}

.input-icon input {
  padding-right: 4em;
  padding-left: 4em;
}

.input-icon .fa-plane {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: linear-gradient(45deg, #34D0B6, #07A0C3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.arrow {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-right: 2px solid #000;
  border-bottom: 2px solid #000;
  transform: rotate(45deg); /* Initial arrow pointing down */
  transition: transform 0.3s ease; /* Smooth rotation animation */
}

.arrow.rotated {
  transform: rotate(-135deg); /* Arrow pointing up */
}

@media (max-width: 1000px) {
  .input-icon {
    position: relative;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .custom-width {
    width: auto !important;
  }
}
.plane-icon {
  position: absolute;
  right: 19px!important; /* Adjust this value for spacing */
  top: 41%!important;
  transform: translateY(-50%);
  color: #888; /* Adjust the color of the icon */
  z-index: 1;
  pointer-events: none; /* Ensure the icon doesn't interfere with user input */
  font-size: 21px!important;
}


</style>
