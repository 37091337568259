<template>
<div class="position-relative overflow-hidden" dir="ltr">
    <AppNavbar/>
    <div>
      <img
        alt=""
        class="background-image"
        src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fcircles-background.png?alt=media&token=d420088e-66c6-4c95-bd3b-2f218dd36aba">
    </div>
    <ImageBanner :backgroundImage= "'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fride-info-banner.jpg?alt=media&token=b765e21f-2604-4874-a029-3453357e4897'"
                 paragraph="כאן תוכלו להוסיף מידע בנושאים שונים – כל אחד עם כותרת משלו."
                 size="240px" title="פרטי הטיול"/>
  <b-container>
    <b-row class="justify-content-center">
      <b-col col md="6">
        <b-form>
          <b-form-group
              id="label-title"
              class="text-right"
              label="הכותרת של הפיסקה"
              label-for="title">
            <b-form-input
                v-model="localExtraInformationForRideEditedTitle"
                id="title"
                class="form-control border-primary rounded-2 text-right py-4"
                placeholder="תחבורה ציבורית ברומא"
                required
                type="text"
                style="direction: rtl;"
            ></b-form-input>
          </b-form-group>
          <b-form-group
              id="label-details"
              class="text-right"
              label="פרטים כללים לפיסקה"
              label-for="albumLink">
            <b-form-textarea
                v-model="localExtraInformationForRideEditedContent"
                id="textarea"
                class="border-primary rounded-2 p-3"
                max-rows="6"
                placeholder=
                  "התחבורה הציבורית ברומא כוללת מטרו, אוטובוסים וחשמליות, ומכסה היטב את האתרים המרכזיים בעיר. מומלץ לרכוש כרטיס יומי או שבועי בתחנות או בקיוסקים. כדאי להוריד אפליקציה כמו Moovit כדי לבדוק זמני הגעה בזמן אמת."
                rows="4"
                style="direction: rtl;"
            ></b-form-textarea>
          </b-form-group>
        </b-form>

        <div>
          <PrimaryButton 
            v-if="this.extraInformationForRideEditedId"
            class="w-100 mb-5"
            @click="this.updateExtraInformationForRide"
          >
            עדכון
          </PrimaryButton>
          <PrimaryButton 
            v-else
            class="w-100 mb-5"
            @click="this.addExtraInformationForRide"
          >
            שמירה
          </PrimaryButton>

          <PrimaryButton 
            v-if="this.extraInformationForRideEditedId"
            class="w-100 mb-5 btn-red"
            @click="this.deleteExtraInformationForRide"
          >
            מחיקת הפיסקה
          </PrimaryButton>


          <SecondaryButton
            :callBack="ReturnToSingleRide"
            class="w-100 mb-5"
          >
            חזרה לטיול
          </SecondaryButton>
        </div>
      </b-col>
    </b-row>
  </b-container>
  <AppFooter/>
</div>
</template>

<script>
import ImageBanner from "@/components/ImageBanner/ImageBanner.vue";
import PrimaryButton from "@/components/Buttons/PrimaryButton.vue";
import AppNavbar from "@/components/Template/AppNavbar.vue";
import AppFooter from "@/components/Template/AppFooter.vue";
import SecondaryButton from "@/components/Buttons/SecondaryButton.vue";

import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ExtraInformationForRide',
  components: {
    AppFooter,
    PrimaryButton,
    ImageBanner,
    AppNavbar,
    SecondaryButton,
    },
  computed: {...mapGetters([  'isAppLoggedIn',
                              'extraInformationForRideEditedTitle',
                              'extraInformationForRideEditedContent',
                              'extraInformationForRideEditedId',
                              ]),
      localExtraInformationForRideEditedTitle:{
        get () {
          return this.extraInformationForRideEditedTitle
        },
        set (value) {
          this.$store.commit('setExtraInformationForRideEditedTitle', value )
        }
      },
      localExtraInformationForRideEditedContent:{
        get () {
          return this.extraInformationForRideEditedContent
        },
        set (value) {
          this.$store.commit('setExtraInformationForRideEditedContent', value )
        }
      },
    },
    methods:
    {
    ...mapActions([ 'ReturnToSingleRide',
                    'showModal',
                    'extraInformationForRideLoadDefault',
                    'addExtraInformationForRide',
                    'updateExtraInformationForRide',
                    'deleteExtraInformationForRide',
                    ] ),
  },
  created() {
    const rideIdFromUrl = this.$route.params.ride_id;
    console.log("this istthe rifde id: ",rideIdFromUrl)
    this.$store.commit('setExtraInformationForRideRideId', rideIdFromUrl )
    if (!this.isAppLoggedIn) {
      this.showModal("pleaseLogInInOrderToEditRide");
    }
  },
  destroyed(){
    this.extraInformationForRideLoadDefault()

  },
};
</script>


<style scoped>
  .form-control{
    background-color: white !important;
  }
  .btn-red {
    background: red; /* Adjust the shade of green as needed */
    color: white; /* Change text color if needed */
  }
</style>