import config from '../../common/config';

const state = {
  subdomain: "",
  theme: {
    userId: null,
    logo: require("@/assets/images/Logo.svg"),
    logoURL: "/",
    colors: {
      primary: "#000000",
      secondary: "#ffffff",
    },
  },
};

const getters = {
  getTheme: (state) => state.theme,
  getSubdomain: (state) => state.subdomain,
  isSubdomain: (state) => state.subdomain !== "default",
  isIdIsThisDomainRideManager: (state) => (userId) => {
    const currentDomain = state.subdomain;
    const theme = config.config.THEMES[currentDomain];
    return theme && theme.userId === userId;
  },
};

const actions = {
  detectSubdomain({ commit }) {
    const hostname = window.location.hostname;
    const parts = hostname.split(".");
    let subdomain = parts.length > config.config.NUMBER_OF_DOTS_AT_URL ? parts[0] : "default";
    commit("SET_SUBDOMAIN", subdomain);
    commit("APPLY_THEME", subdomain);
  },
};

const mutations = {
  SET_SUBDOMAIN(state, subdomain) {
    state.subdomain = subdomain;
  },
  APPLY_THEME(state, subdomain) {

    state.theme = config.config.THEMES[subdomain] || config.config.THEMES["default"];

    // Update CSS variables dynamically
    if (state.theme && state.theme.colors) {
      document.documentElement.style.setProperty('--primary-color', state.theme.colors.primary);
      document.documentElement.style.setProperty('--primary-text-color', state.theme.colors.primaryTextColor);
      document.documentElement.style.setProperty('--secondary-color', state.theme.colors.secondary);
      document.documentElement.style.setProperty('--secondary-text-color', state.theme.colors.secondaryTextColor);
      document.documentElement.style.setProperty('--primary-background', state.theme.colors.primaryBackground);
      document.documentElement.style.setProperty('--secondary-background', state.theme.colors.secondaryBackground);
      document.documentElement.style.setProperty('--main-text-background', state.theme.colors.mainTextBackgroundColor);
      document.documentElement.style.setProperty('--ride-itinerary-background', state.theme.colors.rideItineraryBackgroundColor);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};