<template>
  <div class="trip-card-bottom">
    <div
      v-if="isUserManager && compareToUserId(allRide.ride.authorId) || isSubdomain"
      class="row">
      <div 
        class="col-lg-6 col-md-6">
        <div 
          class="trip-card2-wrap">
          <div class="trip-card2-thumb">
            <img 
              v-if="userExtraInformation"
              :src="userExtraInformation.profile_image"
              @click="fetchSerchAttractionsFromRideMangerAcount()">
          </div>
          <div class="trip-card2-data">
            <h3>פעילויות של מנהל הטיול</h3>
          </div>
        </div>
      </div>
    </div>
    <div 
      v-else
      class="row">
      <div 
        class="col-lg-6 col-md-6">
        <div
          class="trip-card2-wrap">
          <div class="trip-card2-thumb">
            <img 
              src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2Fgoogle-phone.png?alt=media&token=9c742f37-6095-42a9-a8fc-da28b616e9e9"
              @click="fetchSerchAttractionsByRide( 'google' )">
          </div>
          <div class="trip-card2-data">
            <h3>פעילויות מומלצות מ Google </h3>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6">
        <div class="trip-card2-wrap">
          <div class="trip-card2-thumb">
            <img 
              src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2Ffamaly-at-forest.png?alt=media&token=304fe349-36fd-4458-b78f-4844c4fd135c"
              @click="fetchSerchAttractionsByRide( 'internal' )"
              >
          </div>
          <div class="trip-card2-data">
            <h3>פעילויות מומלצות מטיולים אחרים </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    computed: { ...mapGetters( [  'allRide',
                                  'isUserManager',
                                  'userExtraInformation',
                                  'compareToUserId',
                                ]),
                ...mapGetters("theme",  [ "isSubdomain",
                                        ]),

    
    },

    methods: {...mapActions([ 'fetchSerchAttractionsByRide',
                              'fetchSerchAttractionsFromRideMangerAcount',
                            ]),    
    }
}
</script>


<style scoped>
</style>