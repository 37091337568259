import axios from 'axios';
import config from '../common/config';

export default {
  fetchUserRides(token) {
    return axios.get(`${config.config.ROOT_URL}/user_rides_api`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  },
  addRideToUser(token, rideId ) {
    return axios.get(`${config.config.ROOT_URL}/add_ride_to_user_api/${rideId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  },
  copyPrivateRide(token, rideId ) {
    return axios.get(`${config.config.ROOT_URL}/copy_private_ride_api/${rideId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  },
};
