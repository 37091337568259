<template>
  <div>
    <section class="mt-5">
      <InnerNav />
    </section>
    <section class="py-5 sectionWithCards">
      <b-container>
        <b-row v-for="(accommodations, index) in allAccommodations" :key="index">
          <b-col md="12" class="text-right my-5">
            <h5 v-if="index=='baseRide'" class="font-weight-bold ride_checklist_internal_text">הזמנות הלינה ביחס לטיול המקורי:</h5>
            <h5 v-else-if="index=='ride'" class="font-weight-bold ride_checklist_internal_text">הזמנות הלינה שלי:</h5>
            <h5 v-else class="font-weight-bold ride_checklist_internal_text">{{ index }}</h5>
          </b-col>
          <b-col>
            <b-card
              class="shadow border-0 mb-5"
              v-for="(item, idx) in accommodations"
              :key="idx"
            >
              <b-card-body>
                <b-row class="d-flex align-items-center">
                  <b-col cols="8">
                    <b-btn 
                      v-if="index=='ride'"
                      @click="goToRideAccommodation( item )"
                      pill block 
                      style="font-weight:bold; border-color: #14c92c; color: #14c92c; background-color:#e3fffd;"
                      variant="outline-warning"
                      class="py-3 ride_checklist_internal_text">
                    צפיה בהזמנה
                    </b-btn>
                    <b-btn 
                      v-else-if="item.ordered_by_user"
                      @click="goToRideAccommodation( item.paralel_ride_accommodation )"
                      pill
                      block
                      style="font-weight:bold; border-color: #14c92c; color: #14c92c; background-color:#e3fffd;"
                      variant="outline-warning"
                      class="py-3 ride_checklist_internal_text">
                    ההזמנה רשומה במערכת
                    </b-btn>
                    <b-btn v-else pill @click="goToBaseRideAccommodation( item )"
                           style="font-weight:bold; color: #ffb600; background-color:#ffffff;"
                           variant="outline-warning"
                           class="py-3 ride_checklist_internal_text">
                    מציאת מקום לינה
                    </b-btn>
                  </b-col>
                  <b-col class="d-flex text-right">
                    <div class="ml-auto mr-3">
                      <h5
                        class="card-title text-uppercase mb-1 ride_checklist_internal_text"
                        style="
                          font-weight: 600;
                          text-align: right;
                          letter-spacing: 0.045em;
                        "
                      >
                        {{ item.cuntry_and_city }}
                      </h5>
                      <span
                        class="font-weight-bold mb-0 text-muted ride_checklist_internal_text"
                        style="
                          font-weight: normal;
                          text-align: right;
                          letter-spacing: 0.045em;
                          color: rgba(13, 47, 41, 0.3);
                        "
                        >{{ item.check_in_date }} - {{ item.check_out_date }}</span
                      >
                    </div>
                    <img
                      :src="require('@/assets/images/icons/' + 'icons8-hotel-bed-50.png')"
                      width="40"
                      height="40"
                    />
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'SingleRide',
  components:{
    InnerNav: () => import("@/components/InnerNav.vue"),
  },
  computed: mapGetters([  'allRide',
                          'allAccommodations',
                          'isAppLoggedIn',
                          'userId',
                          'compareToUserId' ]),
  methods: { ...mapActions([  'fetchAccommodations',
                              'goToRideAccommodation',
                              'goToBaseRideAccommodation',
                              'addRideToUser',
                              'removeDayFromRide',
                              'addNewDayToRide',
                              'removeAttractionFromRide',
                              'validateAndupdateAppToken',
                              'openLoginForm',
                               ]),
  },
  created() {
    this.validateAndupdateAppToken();
    this.fetchAccommodations();
  },
  // this is aworkaround to fix the problem that booking wizard is working only
  // for the first time we are seting the information, in order to fix it we are
  // refrashing the page, yes i know it is very bad practice!!!!
  // this work arownd is very bad becose it is ckeaning all the information that we lowed!!!
  // if i whant it to work we shoujld relowed the ride information after the relowed!!!
  // this is very bad!!!
  // I should find deferent wey to do it!!!
  // mounted() {
  //   const reloaded = localStorage.getItem('reloaded');
    // if (reloaded !== 'true') {
    //   const rideId = this.$route.params.id;

      // localStorage.setItem('reloaded', 'true');
      // location.reload();
      // this.$router.push('/single_ride_with_map/' + rideId );
      // this.$router.push('/ride_checklist/' + rideId );
      // lowed accommodation

  //  }  
  //  },
  //  destroyed() {
  //   localStorage.setItem('reloaded', 'false');

  //  }
};
</script>

<style scoped>
.ride-container {
  column-count: 3;
  column-gap: 0;
}
img {
  max-width: 100%;
  padding: 5px;
}



/* The popup form - hidden by default */
.form-popup {

  margin: auto;
  width: 50%;
  border: 3px solid green;
  padding: 10px;

  display: none;
  position: fixed;
  bottom: 15px;
  /* right: 150px; */
  border: 3px solid #f1f1f1;
  /* z-index: 9; */
}

.myBottem{
  /* font-family: Assistant; */
  font-style: normal;
  font-weight: bold;
}
</style>
