import axios from 'axios';
import config from '../common/config';

export default {
  getAssignedRideKeyInformation( rideAssignedKey )
  {
    const formData = new FormData()
    formData.append('rideAssignedKey', rideAssignedKey);
    return axios.post(`${config.config.ROOT_URL}/get_assigned_ride_key_information`,formData, {
      headers: {
        'content-type': 'multipart/form-data'        }
    });
  },

  getRedirectLinkFromRideId( rideId )
  {
    const formData = new FormData()
    formData.append('rideId', rideId);
    return axios.post(`${config.config.ROOT_URL}/get_redirect_page_from_ride_id`,formData, {
      headers: {
        'content-type': 'multipart/form-data'        }
    });
  },

  assignUserToLockedRide( token, rideKeyValue, subdomain )
    {
      
      const formData = new FormData()
      formData.append('rideKeyValue', rideKeyValue);
      formData.append('subdomain', subdomain);
      return axios.post(`${config.config.ROOT_URL}/add_user_to_ride_privacy_api`,formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'content-type': 'multipart/form-data'        }
      });
    },

    getOrCreateFullRideAccesskeyInformation( token, rideId )
    {
      const formData = new FormData()
      formData.append('rideId', rideId);
      return axios.post(`${config.config.ROOT_URL}/get_assigned_ride_key_full_information`,formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'content-type': 'multipart/form-data'        }
      });
    },

    updateRideAccessKeyInformation( token, rideAccessKeyInformation)
    {
      const formData = new FormData()
      formData.append('rideAccessKeyInformation', JSON.stringify(rideAccessKeyInformation));
      return axios.post(`${config.config.ROOT_URL}/update_assigned_ride_key_full_information`,formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'content-type': 'multipart/form-data'        }
      });
    },

  
   
};
