import axios from 'axios';
import config from '../common/config';

export default {
  getExtraInformationForRide( token, rideInformationId ) {
    return axios.get(`${config.config.ROOT_URL}/ride_information_api/${rideInformationId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  },
  addExtraInformationForRide( token, extraInformationForRide){
    const formData = new FormData()
    formData.append('data', JSON.stringify( extraInformationForRide ) );
    
    return axios.post(`${config.config.ROOT_URL}/add_extra_information_to_ride`,formData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'content-type': 'multipart/form-data'        }
    });
  },

  updateExtraInformationForRide( token, extraInformationForRide){
    const formData = new FormData()
    formData.append('data', JSON.stringify( extraInformationForRide ) );
    
    return axios.post(`${config.config.ROOT_URL}/update_extra_information_to_ride`,formData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'content-type': 'multipart/form-data'        }
    });
  },

  deleteExtraInformationForRide( token, extraInformationForRide){
    const formData = new FormData()
    formData.append('data', JSON.stringify( extraInformationForRide ) );
    
    return axios.post(`${config.config.ROOT_URL}/delete_extra_information_to_ride`,formData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'content-type': 'multipart/form-data'        }
    });
  },
};
