import config from '@/common/config';
import api from '../../api/manageRidePrivacy';

const state = {
  rideAccesskeySecretKey: null,
  rideAccesskeyRedirectPage: null,
  rideAccesskeyConsultingMeetingCalendarLink: null,
  rideAccesskeyAuthorizedUsersCount: 4,
  rideAccesskeyAction: config.config.RideAccessActions.VIEW,
};

const getters = {
  rideAccesskeySecretKey: state => state.rideAccesskeySecretKey,
  rideAccesskeyRedirectPage: state => state.rideAccesskeyRedirectPage,
  rideAccesskeyConsultingMeetingCalendarLink: state => state.rideAccesskeyConsultingMeetingCalendarLink,
  rideAccesskeyAuthorizedUsersCount: state => state.rideAccesskeyAuthorizedUsersCount,
  rideAccesskeyAction: state => state.rideAccesskeyAction,
  rideAccesskeyActionIsEdit: state => state.rideAccesskeyAction === config.config.RideAccessActions.COPY,
};

const actions = {
  rideAccesskeyLoadDefault({ commit }) {
    commit('setRideAccesskeySecretKey', null);
    commit('setRideAccesskeyRedirectPage', null);
    commit('setRideAccesskeyConsultingMeetingCalendarLink', null);
    commit('setRideAccesskeyAuthorizedUsersCount', 4);
    commit('setRideAccesskeyAction', config.config.RideAccessActions.VIEW);
  },

  async getRideAccessKey({ dispatch, rootState, commit }, rideId ) {
    const { apptoken } = rootState.appauth;
    await dispatch('rideAccesskeyLoadDefault');
    if (apptoken) {
      if ( rideId ){
        const response = await api.getOrCreateFullRideAccesskeyInformation(apptoken, rideId)
        const data = response.data.rideData;
        const rideUrl = 'https://' + (data.subdomain ? data.subdomain + '.' : '') + 'tripplanner.co.il/assign_to_ride/';
        commit('setRideAccesskeySecretKey', rideUrl + data.secret_key);
        commit('setRideAccesskeyRedirectPage', data.redirect_page);
        commit('setRideAccesskeyConsultingMeetingCalendarLink', data.consulting_meeting_calendar_link);
        commit('setRideAccesskeyAuthorizedUsersCount',  data.authorized_users_count);  
        commit('setRideAccesskeyAction',  data.action);  
      }
    }
  },

  async updateRideAccessKey({ state, rootState }, rideId ) {
    const { apptoken } = rootState.appauth;
    const rideAccessKeyInformation = {
      ride_id: rideId,
      redirectPage: state.rideAccesskeyRedirectPage,
      authorizedUsersCount: state.rideAccesskeyAuthorizedUsersCount,
      action: state.rideAccesskeyAction,
    };
    await api.updateRideAccessKeyInformation(apptoken, rideAccessKeyInformation);    
  },
};

const mutations = {
  setRideAccesskeySecretKey: (state, rideAccesskeySecretKey) => {
    state.rideAccesskeySecretKey = rideAccesskeySecretKey;
  },
  setRideAccesskeyRedirectPage: (state, rideAccesskeyRedirectPage) => {
    state.rideAccesskeyRedirectPage = rideAccesskeyRedirectPage;
  },
  setRideAccesskeyConsultingMeetingCalendarLink: (state, rideAccesskeyConsultingMeetingCalendarLink) => {
    state.rideAccesskeyConsultingMeetingCalendarLink = rideAccesskeyConsultingMeetingCalendarLink;
  },
  setRideAccesskeyAuthorizedUsersCount: (state, rideAccesskeyAuthorizedUsersCount) => {
    state.rideAccesskeyAuthorizedUsersCount = rideAccesskeyAuthorizedUsersCount;
  },
  setRideAccesskeyAction: (state, rideAccesskeyAction) => {
    const actionMapping = {
      "true": config.config.RideAccessActions.COPY,
      "false": config.config.RideAccessActions.VIEW,
    };
    const key = String(rideAccesskeyAction);
    state.rideAccesskeyAction = actionMapping[key] !== undefined ? actionMapping[key] : key;
  },
  

};

export default {
  state,
  getters,
  actions,
  mutations
};
