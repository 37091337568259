<template>
    <b-modal
      id="mainRegistration"
      modal-class="modal-cover"
      v-model="isVisible"
      hide-backdrop
      hide-footer
      content-class="shadow-lg"
      centered
    >

  <!-- <b-modal hide-footer centered v-model="isVisible"> -->
    
  <!-- Modal -->
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <div class="trip-login-wrap">
            <h3>הרשמה</h3>
            <div class="login-o-ath">  
              <a 
                class="trip-google-btn"
                @click="loginToAppWithGoogle">
                <img src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Flog_in%2Fgoogle.png?alt=media&token=60857ff1-650c-464f-861b-2aabdcb78df7" alt="">
              </a>
              <a 
                class="trip-google-btn"
                @click="loginToAppWithFaceBook">
                <img 
                  class="facebook_limit_image_size"
                  src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Flog_in%2Ffacebookk_login.png?alt=media&token=448d8ae2-e4dd-4b27-9432-24034e1c36a8"
                  alt=""
                >
              </a>
            </div>
            <div class="trip-border-content">
              <p>או הרשמו עם</p>
            </div>
            <form action="#">
              <div class="trip-login-field">
                <b-form-input
                  @input="updateUserName"
                  autocapitalize="none"
                  placeholder="שם משתמש"
                  class="log_in_medium text-right"
                  type="text">
                </b-form-input>
              </div>
              <div class="trip-login-field">
                <b-form-input
                  dir="ltr"
                  autocapitalize="none"
                  :value="email"
                  @input="updateEmail"
                  id="email"
                  placeholder="אימייל"
                  class="log_in_medium  text-right"
                  required>
                </b-form-input>
              </div>
              <div class="trip-login-field">
                <b-form-input
                  :value="password"
                  autocapitalize="none"
                  @input="updatePassword"
                  placeholder="סיסמא"
                  class="log_in_medium text-right"
                  type="password">
                </b-form-input>
              </div>
              <div class="trip-login-field">
                <b-form-input
                  :value="rePassword"
                  autocapitalize="none"
                  @input="updateRePassword"
                  placeholder="סיסמא פעם נוספת"
                  class="log_in_medium text-right"
                  type="password">
                </b-form-input>
              </div>
              <div class="trip-login-check">
                <input type="checkbox" id="html">
                <label for="html">
                  <b-link 
                    @click="goToTermsOfUse">
                    קראתי את התקנון
                  </b-link>
                </label>
                <h5>על ידי המשך התהליך אני מסכים לתנאי השימוש
                  ולמדינויות הפרטיות של האתר</h5>
              </div>
              <button
                id="trip-login-btn2"
                type="button"
                @click="newUserRegistration">
                הרשמו
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- End modal -->    
    
  </b-modal>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  props: ["value"],
  computed: {
     ...mapGetters([  'email',
                      'password',
                      'rePassword',
                      'userName',
                      'isNewUser']),
    isVisible: {
      set(val) {
        this.$emit("input", val);
      },
      get() {
        return this.value;
      },
    },
  },
  methods: 
  {
    ...mapActions(['register',
                   'authenticate',
                   'registerNewUser',
                   'validateAndupdateAppToken',
                   'goToTermsOfUse',
                   'loginToAppWithGoogle',
                   'loginToAppWithFaceBook',
                   ]),
    newUserRegistration () {
      const password  = this.password;
      const rePassword  = this.rePassword;
      if( password != rePassword ){ 
        this.$store.commit('setRePassword', '');
        this.$store.commit('setPassword', '');
        return;
      }
      this.$store.dispatch('registerNewUser');
      this.$store.dispatch( 'showModal', "" );
    },   
    updateEmail (value) {
      this.$store.commit('setEmail', value)
    },
    updateUserName (value) {
      this.$store.commit('setUserName', value)
    },
    updatePassword (value) {
      this.$store.commit('setPassword', value)
    },
    updateRePassword (value) {
      this.$store.commit('setRePassword', value)
    },
  },

};
</script>
<style scoped>
/* modal */
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 45px 55px;
}
.modal-dialog {
  max-width: 310px;
  
  margin: 1.75rem auto!important;
  margin-top: 10%!important;
  
}
.modal-content {
  background: #FFFFFF;
  box-shadow: 4px 4px 4px rgb(0 0 0 / 10%);
  border-radius: 25px;
}
.trip-login-wrap{
  text-align: center;
}
.trip-login-field{
  margin-bottom: 10px;
}
.trip-login-field input{
  height: 30px;
  width: 100%;
  text-align: right;
  border: 1px solid #C1C0C0;
  border-radius: 22px;
  font-size: 12px;
  line-height: 14px;
  padding: 5px 15px!important;
  color: black;
}
button#trip-login-btn, 
button#trip-login-btn2 {
  width: 100%;
  height: 30px;
  background: #34D0B6;
  border-radius: 22px;
  border: none;
  font-weight: 500;
  font-size: 12px;
  color: white;
  margin-top: 15px;
}
button#trip-login-btn2{
  background-color:  #07A0C3;
}

.trip-login-wrap h3{
  font-weight: 800;
  font-size: 18px;
  line-height: 21px;
  color: #2E2E3A;
  margin-bottom: 50px;
  text-align: center
}
.trip-border-content{
  text-align: center;
  margin-top: 20px;
  margin-bottom: 12px;
}
.trip-border-content p{
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #2E2E3A;
  text-align: center;
  position: relative;
  z-index: 0;
  display: inline-block;
  background-color: #fff;
}
.trip-border-content p:before, 
.trip-border-content p:after {
  content: " ";
  left: -60px;
  top: 7px;
  width: 87%;
  height: 1px;
  display: inline;
  position: absolute;
  background-color: #2E2E3A;
  z-index: -1;
}
.trip-border-content p:after{
  left: auto;
  right: -60px;
}
.trip-login-bottom-btn{
  margin-top: 25px;
}
.trip-login-bottom-btn h5{
  font-size: 10px;
  line-height: 12px;
  color: #2E2E3A;
  margin-bottom: 5px;
}

button.trip-login-btn {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #FFFFFF;
  width: 129px;
  height: 30px;
  background: #07A0C3;
  border-radius: 22px;
  border: none;
}
.trip-login-check {
  text-align: right;
}
.trip-login-check h5{
  font-size: 9px;
  line-height: 11px;
  text-align: right;
  color: #2E2E3A;
  margin-top: 7px;
}
.trip-login-check label{
  font-size: 10px;
  line-height: 12px;
  color: #2E2E3A;
}
.trip-login-check input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.trip-login-check label {
  position: relative;
  cursor: pointer;
}

.trip-login-check label:before {
  content:'';
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #2E2E3A;
  padding: 0 6.5px;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-left: 5px;
  border-radius: 3px;
}

.trip-login-check input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 3px;
  right: 6px;
  width: 5px;
  height: 8px;
  border: solid #2E2E3A;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* contact page */
.trip-card-top.trip-card-top2{
  text-align: left;
  position: relative;
}
.trip-card-top h4 {
  font-weight: 600;
  font-size: 30px;
  line-height: 41px;
  color: #0D2F29;
  display: inline-block;
  position: absolute;
  top: 0;
  margin: 0;
  left: 50%;
  transform: translateX(-50%);
}
.trip-contact-wrap h4{
  display: none;
}
.trip-contact-field {
  margin-bottom: 15px;
}
.trip-contact-field label{
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-align: right;
  color: #110703;
  display: block;
  margin-bottom: 5px;
  margin-right: 40px;
  }
  .trip-contact-field label span{
    color: #E42424;
  }
  
  .trip-contact-input{
    position: relative;
  }
  .trip-contact-field img{
    width: 100%;
  }
  .trip-contact-input input, 
  .trip-contact-input textarea{
    height: 56px;
    width: 100%;
    background: #FFFFFF;
    border: 2px solid #07A0C3!important;
    border-radius: 45px;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: right;
    padding: 10px 50px 10px 160px!important;
    color: rgba(13, 47, 41, 0.5)!important;

  }
  .trip-contact-input textarea{
    height: 300px;
  }
  .trip-input-icon {
    position: absolute;
    top: 13px;
    right: 25px;
  }
  .trip-contact-input p{
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    margin:15px 0 0;
    color: rgba(228, 36, 36, 0.6)
  }
.trip-contact-input input::-webkit-input-placeholder, 
.trip-contact-input textarea::-webkit-input-placeholder{ /* WebKit, Blink, Edge */
    color: rgba(13, 47, 41, 0.5);
    opacity: 1;
}
.trip-contact-input input:-moz-placeholder, 
.trip-contact-input textarea:-moz-placeholder{ /* Mozilla Firefox 4 to 18 */
  color: rgba(13, 47, 41, 0.5);
   opacity:  1;
}
.trip-contact-input input::-moz-placeholder, 
.trip-contact-input textarea::-moz-placeholder{ /* Mozilla Firefox 19+ */
  color: rgba(13, 47, 41, 0.5);
   opacity:  1;
}
.trip-contact-input input:-ms-input-placeholder, 
.trip-contact-input textarea:-ms-input-placeholder{ /* Internet Explorer 10-11 */
  color: rgba(13, 47, 41, 0.5);
   opacity: 1;
}
.trip-contact-input input::-ms-input-placeholder, 
.trip-contact-input textarea::-ms-input-placeholder{ /* Microsoft Edge */
  color: rgba(13, 47, 41, 0.5);
   opacity: 1;
}

.trip-contact-input input::placeholder, 
.trip-contact-input textarea::placeholder{ /* Most modern browsers support this now. */
  color: rgba(13, 47, 41, 0.5);
   opacity: 1;
}

button#submit-btn1 {
  height: 56px;
  background: #0D2F29;
  border-radius: 45px;
  width: 100%;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: #FFFFFF;
}
button#submit-btn2 {
    width: 188px;
    height: 42px;
    background: #DD6E42;
    border-radius: 45px;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    border: none;
    margin: 0 auto;
}

.trip-submit-button{
  margin-top: 50px;
  text-align: center;
}

.trip-contact-form-section {
  padding-bottom: 150px;
}
.trip-contact-siderbar{
  background: rgba(225, 246, 251, 0.5);
  border-radius: 18px;
  padding: 25px;
  margin-left: 50px;
}
.trip-contact-siderbar h5{
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #E42424;
}

.trip-card2-thumb-resposive {
  display: none;
}
.trip-card2-thumb-resposive img{
  width: 100%;
  height: 100%;
}

.close-icon {
  border: 1px solid transparent;
  background-color: transparent;
  display: inline-block;
  vertical-align: middle;
  outline: 0;
  cursor: pointer;
  position: absolute;
  left: 13px;
  top: 13px;
  font-weight: 800;
  font-size: 18px;
  line-height: 24px;
  text-align: right;
  color: #FF0000;
}
.close-icon img{
  width: auto;
}
.input-remove:not(:valid) ~ .close-icon {
	display: none;
}




</style>