const getters = {
  getResizeImageForMobileDevices: (getters, rootGetters) => (imageUrl) => {
    if (rootGetters.isMobile) {
      try {
        const delimiter = "?alt=";
        if (!imageUrl.includes(delimiter)) {
          throw new Error("Delimiter not found");
        }
  
        const [base, query] = imageUrl.split(delimiter);
  
        // Find last slash to isolate filename
        const lastSlashIndex = base.lastIndexOf('/');
        if (lastSlashIndex === -1) throw new Error("Invalid URL structure");
  
        const filename = base.substring(lastSlashIndex + 1);
        const pathBeforeFile = base.substring(0, lastSlashIndex + 1);
  
        // Check for file extension (like .png, .jpg)
        const dotIndex = filename.lastIndexOf('.');
  
        let resizedFilename;
        if (dotIndex !== -1) {
          // If there is an extension, insert before it
          resizedFilename =
            filename.substring(0, dotIndex) + "_300x300" + filename.substring(dotIndex);
        } else {
          // No extension, just append _300x300
          resizedFilename = filename + "_300x300";
        }
  
        const resizedUrl = pathBeforeFile + resizedFilename + delimiter + query;
        return resizedUrl;
      } catch (error) {
        console.warn("smalerImage error:", error.message);
        return imageUrl;
      }
    }
    return imageUrl;
  }
};


export default {
   getters,
};
