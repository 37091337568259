import { render, staticRenderFns } from "./PrivatePageLanding.vue?vue&type=template&id=be9e3c64&scoped=true"
import script from "./PrivatePageLanding.vue?vue&type=script&lang=js"
export * from "./PrivatePageLanding.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "be9e3c64",
  null
  
)

export default component.exports