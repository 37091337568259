<template>
  <div>
    <h1 v-if=globalRides style="text-align:center">רשימת הטיולים </h1>
    <p></p>
    <div v-for="globalRide in globalRides" :key="globalRide.id" class="border">
      <div align='center'>
        <h2>{{ globalRide.title }}</h2>
        <button  type="button" class="btn btn-primary" @click="goToRide(globalRide.id)"> צפה וערוך טיול זה</button>
        <p>{{globalRide.text}}</p>
        <p>מבוגרים : {{globalRide.number_of_adult}}</p>
        <p>נערים : {{globalRide.number_of_teen}}</p>
        <p>ילדים : {{globalRide.number_of_child}}</p>
        <p>מתאים לאנשים עם מוגבלות : {{globalRide.physical_disabilities}}</p>
        <Gallery :images="globalRide.rideImages" ></Gallery>
      </div>
      <p></p>
      <p></p>
    
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import Gallery from "vue-cover-gallery";
// import VueGallery from 'vue-gallery';
  
 
export default {
  data() {
    return {
      images: [
            {
            title: "title 1",
            description: "des 1",
            href: "https://www.exapleimages.com/first.jpg"},
            {
             title: "title 2",
            description: "des 2",
            href: "https://www.exapleimages.com/second.jpg"
            },
     ],
    };
  },  // data: function () {
  //     return {
  //       images: [
  //         'https://dummyimage.com/800/ffffff/000000',
  //         'https://dummyimage.com/1600/ffffff/000000',
  //         'https://dummyimage.com/1280/000000/ffffff',
  //         'https://dummyimage.com/400/000000/ffffff',
  //       ],
  //       indexr: null
  //     };
  // },
  name: 'GlobalRides',
  components: {
      Gallery
      // 'gallery': VueGallery
    },
  computed: mapGetters(['globalRides', 'userId' ]),
  methods: { ...mapActions([  'fetchGlobalRides',
                              'removeAttractionFromRide',
                              'validateAndupdateAppToken', ]),
    curentuserIsTheRideAuthor( attractionAuthorId ){
      if( this.userId == attractionAuthorId ){
        return true;
        }
      return false
    },
    goToRide( rideId ) {
      this.$router.push('/single_ride_with_map/'+ rideId)

    }
  },
  created() {
    this.validateAndupdateAppToken();
    this.fetchGlobalRides();
  }
};
</script>

<style scoped>


.image {
  float: left;
  /* background-size: cover; */
  background-repeat: no-repeat;
  /* background-position: center center; */
  border: 1px solid #ebebeb;
  margin: 5px;
  
}
 



.ride-container {
  column-count: 3;
  column-gap: 0;
}
img {
  max-width: 100%;
  padding: 5px;
}

/* The popup form - hidden by default */
.form-popup {

  margin: auto;
  width: 50%;
  border: 3px solid green;
  padding: 10px;

  display: none;
  position: fixed;
  bottom: 15px;
  /* right: 150px; */
  border: 3px solid #f1f1f1;
  /* z-index: 9; */
}

</style>
