
import api from '../../api/extraInformationForRide';
import { router } from '../../main';

const state = {
  extraInformationForRideEditedTitle: null,
  extraInformationForRideEditedContent: null,
  extraInformationForRideEditedId: null,
  extraInformationForRideRideId: null,
};

const getters = {
  extraInformationForRideEditedTitle: state => state.extraInformationForRideEditedTitle,
  extraInformationForRideEditedContent: state => state.extraInformationForRideEditedContent,
  extraInformationForRideEditedId: state => state.extraInformationForRideEditedId,  
};

const actions = {
  extraInformationForRideLoadDefault({ commit }) {
    commit('setExtraInformationForRideEditedTitle', null);
    commit('setExtraInformationForRideEditedContent', null);
    commit('setExtraInformationForRideEditedId', null);   
    commit('setExtraInformationForRideRideId', null);   
  },

  async openExtraInformationForRide({ rootState }, rideId=null ) {
    const { apptoken } = rootState.appauth;
    console.log( apptoken )
    router.push('/ride_extra_information/' + rideId);
  },

  async addExtraInformationForRide({ state, rootState, dispatch }) {
    var extraInformationForRide = {}
    extraInformationForRide['title'] = state.extraInformationForRideEditedTitle;
    extraInformationForRide['text'] = state.extraInformationForRideEditedContent;
    extraInformationForRide['ride_id'] = state.extraInformationForRideRideId;

    const { apptoken } = rootState.appauth;
    const response = await api.addExtraInformationForRide(apptoken,
      extraInformationForRide);
    if (response.statusText == "OK") {
      const rideId = state.extraInformationForRideRideId
      await dispatch('extraInformationForRideLoadDefault');    // set the ride id to lowad
      router.push('/single_ride_with_map/' + rideId);
    }
    dispatch( 'lockShowModalOnce' );
  },
  
  async editExtraInformationForRide({ getters, commit }, { rideId, extraInformationId }) {
    let extraInformationForEditing = null;
    const rideHeader = getters.rideHeader
    for (const extraInformation of rideHeader.ride.rideExtraInformation) {
      if (extraInformation.id === extraInformationId) {
        extraInformationForEditing = extraInformation;
        break;
      }
    }
    if (!extraInformationForEditing) {
      return;
    }
  
    commit('setExtraInformationForRideEditedTitle', extraInformationForEditing.title);
    commit('setExtraInformationForRideEditedContent', extraInformationForEditing.text);
    commit('setExtraInformationForRideEditedId', extraInformationId);
    commit('setExtraInformationForRideRideId', rideId);
    router.push('/ride_extra_information/' + rideId);
  },

  async updateExtraInformationForRide({ state, rootState, dispatch }) {
    var extraInformationForRide = {}
    extraInformationForRide['title'] = state.extraInformationForRideEditedTitle;
    extraInformationForRide['text'] = state.extraInformationForRideEditedContent;
    extraInformationForRide['extra_information_id'] = state.extraInformationForRideEditedId;
    extraInformationForRide['ride_id'] = state.extraInformationForRideRideId;

    const { apptoken } = rootState.appauth;
    const response = await api.updateExtraInformationForRide( apptoken,
                                                              extraInformationForRide);
    
    if( response.data.ServerMassageCode != "success" ){
      dispatch( 'showModalWithParameters',
      { errorNumber : response.data.ServerMassageCode,
        type:'textConverter' 
      } );
      return
    }
    const rideId = state.extraInformationForRideRideId
    await dispatch('extraInformationForRideLoadDefault');    // set the ride id to lowad
    router.push('/single_ride_with_map/' + rideId);
    dispatch( 'lockShowModalOnce' );
  },

  async deleteExtraInformationForRide({ state, rootState, dispatch }) {
    var extraInformationForRide = {}
    extraInformationForRide['extra_information_id'] = state.extraInformationForRideEditedId;
    extraInformationForRide['ride_id'] = state.extraInformationForRideRideId;

    const { apptoken } = rootState.appauth;
    const response = await api.deleteExtraInformationForRide( apptoken,
                                                              extraInformationForRide);
    
    if( response.data.ServerMassageCode != "success" ){
      dispatch( 'showModalWithParameters',
      { errorNumber : response.data.ServerMassageCode,
        type:'textConverter' 
      } );
      return
    }
    const rideId = state.extraInformationForRideRideId
    await dispatch('extraInformationForRideLoadDefault');    // set the ride id to lowad
    router.push('/single_ride_with_map/' + rideId);
    dispatch( 'lockShowModalOnce' );
  },

};

const mutations = {
  setExtraInformationForRideEditedTitle: (state, extraInformationForRideEditedTitle ) => {
    state.extraInformationForRideEditedTitle = extraInformationForRideEditedTitle;
  },
  setExtraInformationForRideEditedContent: (state, extraInformationForRideEditedContent ) => {
    state.extraInformationForRideEditedContent = extraInformationForRideEditedContent;
  },
  setExtraInformationForRideEditedId: (state, extraInformationForRideEditedId ) => {
    state.extraInformationForRideEditedId = extraInformationForRideEditedId;
  },
  setExtraInformationForRideRideId: (state, extraInformationForRideRideId ) => {
    state.extraInformationForRideRideId = extraInformationForRideRideId;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
