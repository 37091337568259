import axios from 'axios';

import config from '../common/config';

export default {
  addAttractionToRide( token, rideId, attractionId, date, shouldCopyTheAttraction ) {
    const formData = new FormData()
    formData.append('date', date);
    console.log( "the date is : ", date )
    formData.append('shouldCopyTheAttraction', shouldCopyTheAttraction);
    return axios.post(`${config.config.ROOT_URL}/add_attraction_to_ride_api/${rideId}/${attractionId}`,formData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'content-type': 'multipart/form-data',
      }
    });
  },

  addAttractionFromImageToRide( token, atractionMetadataList ) {
    const formData = new FormData()
    formData.append('data', JSON.stringify(atractionMetadataList));
    return axios.post(`${config.config.ROOT_URL}/add_attraction_from_image_to_ride_api`,formData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'content-type': 'multipart/form-data'        }
    });
  },

  updateAttractionRideTime( token, rideId, attractionId, date ) {
    return axios.get(`${config.config.ROOT_URL}/update_attraction_ride_time_api/${rideId}/${attractionId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        data: date
      }
    });
  },

  addgooglePlaceToRide( token, rideId, googlePlaceId, date ) {
    return axios.get(`${config.config.ROOT_URL}/add_google_attraction_to_ride_api/${rideId}/${googlePlaceId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        data: date
      }
    });
  },
  removeAttractionFromRide( token, rideId, attractionId ) {
    return axios.get(`${config.config.ROOT_URL}/remove_attraction_from_ride_api/${rideId}/${attractionId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  },
  addNewDayToRide( token, rideId, dayIndex ) {
    return axios.get(`${config.config.ROOT_URL}/add_day_to_ride_api/${rideId}/${dayIndex}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
  },
  updateRideDuration( token, rideId, newRideDates ) {
    const formData = new FormData()
    formData.append('data', JSON.stringify(newRideDates));
    return axios.post(`${config.config.ROOT_URL}/update_ride_duration_api/${rideId}`, formData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'content-type': 'multipart/form-data'        
      }
    });
  },

  removeDayFromRide( token, rideId, dayIndex ) {
    return axios.get(`${config.config.ROOT_URL}/remove_day_from_ride_api/${rideId}/${dayIndex}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
  },
  createAttraction( token, rideId, attractionInfo )
    {
      const formData = new FormData()
      formData.append('data', JSON.stringify(attractionInfo));
      return axios.post(`${config.config.ROOT_URL}/create_attraction_and_add_to_ride_api/${rideId}`,formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'content-type': 'multipart/form-data'        }
      });
    },
};