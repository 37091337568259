import * as VueGoogleMaps from "vue2-google-maps";

import Vue from 'vue';
import { BootstrapVue, BootstrapVueIcons, IconsPlugin } from "bootstrap-vue";
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import App from './App';
import VueRouter from 'vue-router';
import store from './store';
import config from '@/common/config';

import AboutPage from '@/views/About';
import AddAtractionsImage from '@/views/AddAtractionsImage.vue';
import AddAccommodationsImage from '@/views/AddAccommodationsImage.vue';
import AddAccommodationFromImage from '@/views/AddAccommodationFromImage.vue';
import AddAttractionFromImage from '@/views/AddAttractionFromImage.vue';
import TermsOfUse from '@/views/TermsOfUse';
import DeleteUser from '@/views/DeleteUser';
import PrivacyPolicy from '@/views/PrivacyPolicy';
import AuthHandler from './components/AuthHandler';
import HomePage from '@/views/HomePage';
import HomePageNew from '@/views/HomePageNew';
import RideAccommodation from '@/views/Accommodation';
import BookingDotCom from '@/views/BookingDotCom';
import SingleRideWithMap from '@/views/SingleRideWithMap';
import SingleRideWithMapAndAttraction from '@/views/SingleRideWithMapAndAttraction';
import SingleMapWithAllSearchOnMap from '@/views/SingleMapWithAllSearchOnMap';
import FullScreenSingleRideMap from '@/views/FullScreenSingleRideMap';
import RideChecklist from '@/views/RideChecklist';
import SerchAttractionsWithMap from '@/views/SerchAttractionsWithMap';
import RideManagerAttractions from '@/views/RideManagerAttractions';
import EditingAtractionsUniqnes from '@/views/EditingAtractionsUniqnes';
import SearchRide from '@/views/SearchRide';
import SearchAccommodationWithMap from '@/views/SearchAccommodationWithMap';
import RideAttraction from '@/views/Attraction';
import AttractionEditing from '@/views/AttractionEditing';
import RideInformations from '@/views/RideInformation';
import ExtraInformationForRide from '@/views/ExtraInformationForRide';
import AssignToRide from '@/views/AssignToRide';
import PrivatePageLanding from '@/views/PrivatePageLanding';
import UserRidesV from '@/views/UserRides';
import UploadForm from './components/UploadForm';
import SingleRide from './components/SingleRide';
import SingleRideMap from './components/SingleRideMap';
import GlobalMap from './components/GlobalMap';
import InsertNewRideFromGooglePhotos from '@/views/InsertNewRideFromGooglePhotos';
import SerchAttractions from './components/SerchAttractions';
import CreateAttraction from './components/CreateAttraction';
import UserRides from './components/UserRides';
import RideInformation from './components/RideInformation';
import EditRideAccessKey from './components/EditRideAccessKey';
import LoadScript from 'vue-plugin-load-script';
import GlobalRides from './components/GlobalRides';
import PagePopups from './components/Popups';

// import '@babel/polyfill'
// import 'mutationobserver-shim'
import VueSocialSharing from 'vue-social-sharing'
import VueTextareaAutosize from 'vue-textarea-autosize';
import firebase from 'firebase'

import VideoBackground from 'vue-responsive-video-background-player'
// import VueMeta from 'vue-meta'
import VueMeta from 'vue-meta'

Vue.component('video-background', VideoBackground);



var firebaseConfig = {
  apiKey: config.config.FIRE_BASE_API_KEY,
  authDomain: "tripplanner-308b3.firebaseapp.com",
  projectId: "tripplanner-308b3",
  storageBucket: "tripplanner-308b3.appspot.com",
  messagingSenderId: "869000350964",
  appId: "1:869000350964:web:4afb57434764563e1ee2a1",
  measurementId: "G-2LETZP7VRD"
};


Vue.use(BootstrapVue);
Vue.use(IconsPlugin)
Vue.use(BootstrapVueIcons);
Vue.use(VueSocialSharing);
Vue.use(VueTextareaAutosize);
Vue.config.productionTip = false
// Vue.use(LoaderPlugin, {
//   client_id: '80587839075-a34ut0489s6kljp5rtn5c8t45qqcacmc.apps.googleusercontent.com'
// });



Vue.use(VueMeta)
Vue.use(VueRouter);
// Vue.use(VueMeta)
Vue.use(LoadScript);
const singleRideName = 'single ride with map with id'
export const router = new VueRouter({
  mode: 'history',
  routes: [
    { name: 'HomePage',path: '/', component: HomePageNew },
    { name: 'oldHomePage',path: '/old', component: HomePage },
    { name: 'about',path: '/about', component: AboutPage },
    { name: 'addImages',path: '/add_images', component: AddAtractionsImage },
    { name: 'addImagesToThTrip',path: '/add_images/:ride_id', component: AddAtractionsImage },
    { name: 'addAttractionFromImage',path: '/add_attraction', component: AddAttractionFromImage },
    { name: 'AddAccommodationsImage',path: '/add_accommodations_image', component: AddAccommodationsImage },
    { name: 'AddAccommodationsImageToThTrip',path: '/add_accommodations_image/:ride_id', component: AddAccommodationsImage },
    { name: 'AddAccommodationFromImage',path: '/add_accommodation_from_image', component: AddAccommodationFromImage },
    { name: 'terms of use',path: '/terms_of_use', component: TermsOfUse },
    { name: 'privacy policy',path: '/privacy_policy', component: PrivacyPolicy },
    { name: 'delete user',path: '/delete_user', component: DeleteUser },
    { name: 'upload',path: '/upload', component: UploadForm },
    { name: 'oauth2callback',path: '/oauth2/callback', component: AuthHandler },
    { name: 'single_ride',path: '/single_ride', component: SingleRide },
    { name: 'single ride map',path: '/single_Ride_map', component: SingleRideMap },
    { name: 'single ride with map',path: '/single_ride_with_map', component: SingleRideWithMap },
    { name:  singleRideName ,path: '/single_ride_with_map/:id', component: SingleRideWithMap },
    { name: 'singleMapWithAllSearchOnMap' ,path: '/single_map_with_all_search_on_map', component: SingleMapWithAllSearchOnMap },
    { name: 'fullScreenSingleRideMap',path: '/full_screen_single_ride_map/:id/:dayId', component: FullScreenSingleRideMap },
    { name: "singleRidewithMapAndAtraction" ,path: '/single_ride_with_map_attraction/:id/:attractionId', component: SingleRideWithMapAndAttraction },
    { name: 'editing atractions uniqnes',path: '/editing_atractions_uniqnes', component: EditingAtractionsUniqnes },
    { name: 'ride checklist',path: '/ride_checklist', component: RideChecklist },
    { name: 'ride checklist with id',path: '/ride_checklist/:id', component: RideChecklist },
    // We should remove the global rides
    { name: 'global rides',path: '/global_rides', component: GlobalRides },
    //can we remove this?
    { name: 'global map',path: '/global_map', component: GlobalMap },
    // { name: 'insert new ride from google photos by id',path: '/insert_new_ride_from_google_photos/:ride_id', component: InsertNewRideFromGooglePhotos },
    { name: 'insert new ride from google photos',path: '/insert_new_ride_from_google_photos', component: InsertNewRideFromGooglePhotos },
    { name: 'serch attraction',path: '/serch_attractions', component: SerchAttractions },
    { name: 'search attraction with map',path: '/serch_attractions_with_map', component: SerchAttractionsWithMap },
    { name: 'ride mnager attraction',path: '/ride_manager_attraction', component: RideManagerAttractions },
    { name: 'search ride',path: '/search_ride', component: SearchRide },
    { name: config.config.ROUTES_URL_NAMES.searchRideByName ,path: '/search_ride_by_name/:location_name', component: SearchRide },
    { name: 'search accommodations from ride' ,path: '/search_accommodations', component: SearchAccommodationWithMap },
    { name: config.config.ROUTES_URL_NAMES.searchAccommodationsNyName,path: '/search_accommodations_by_name/:location_name', component: SearchAccommodationWithMap },
    { name: 'add new attraction to ride',path: '/add_new_attraction_to_ride_old', component: CreateAttraction },
    { name: 'attraction editing',path: '/add_new_attraction_to_ride', component: AttractionEditing },
    { name: 'attraction',path: '/attraction', component: RideAttraction },
    { name: 'attractionInforation',path: '/attractionInformation', component: RideAttraction },
    { name: 'accommodation',path: '/accommodation', component: RideAccommodation },
    { name: 'booking dot com',path: '/booking', component: BookingDotCom },
    // We should remove all the old part!!!!!!!!!!!!!
    { name: 'user rides old',path: '/user_rides_old', component: UserRides },
    { name: 'user rides',path: '/user_rides', component: UserRidesV },
    { name: 'the old ride information',path: '/ride_information_old', component: RideInformation },
    { name: 'ride information',path: '/ride_information', component: RideInformations },
    { name: 'ride extra information',path: '/ride_extra_information/:ride_id', component: ExtraInformationForRide },
    { name: 'ride access key information',path: '/ride_access_key_information/:ride_key', component: EditRideAccessKey },
    { name: 'assign to ride with key',path: '/assign_to_ride', component: AssignToRide },
    { name: 'assign to ride',path: '/assign_to_ride/:ride_key', component: AssignToRide, props: { context: 'standalone' } },
    { name: 'assign to ride with consulting',path: '/assign_to_ride_with_consulting/:ride_key', component: AssignToRide, props: { context: 'consulting' } },
    { name: 'private page landing',path: '/private_page_landing/:ride_id', component: PrivatePageLanding },
    { name: 'popups',path: '/popups', component: PagePopups },
  ],
  /* eslint-disable no-unused-vars */
  scrollBehavior( to, _from, _savedPosition ) {
    if ( to.name == singleRideName ){
      var reload = parseInt( localStorage.getItem('workAroundToSetTheScroleLocationAtTheMAinPage') );
      return {x: 0 , y: reload }
    }
    return {x: 0, y: 0}
  }
});
// from faceBook url: https://localhost:1024/single_ride_with_map/1?utm_source=fb&utm_medium=display&utm_campaign=poland_183%20%D7%A7%D7%9E%D7%A4%D7%99%D7%99%D7%9F&utm_content=Facebook_Desktop_Feed&fbclid=IwAR3262QkKPBK2JwSSLiS-K5m07w4hO4-aq2Oaw7OkD1dUZGRzmlFaFGMQ-w 
router.beforeEach((to, from, next) => {
  try{
    store.commit('setLabel',to.query);
  } catch ( error ){
    console.error('An error occurred:', error);
  }
  const loadNewCssPageNames = [ 'addImages',
                                'addImagesToThTrip',
                                'addAttractionFromImage',
                                'AddAccommodationsImage',
                                'AddAccommodationsImageToThTrip',
                                'AddAccommodationFromImage',
                                'ride information',
                                'ride extra information',
                                'assign to ride with key',
                                'assign to ride',
                                'assign to ride with consulting',
                                'private page landing',
                                'search ride',
                                'ride access key information',
                                'ride mnager attraction',
                                config.config.ROUTES_URL_NAMES.searchRideByName,
                              ];
  const movingFromTheSameCssPageType = 
  ((loadNewCssPageNames.includes(to.name)&&
    loadNewCssPageNames.includes(from.name))||
    (!loadNewCssPageNames.includes(to.name)&&
    !loadNewCssPageNames.includes(from.name)&&
    from.name!=null));

  if ( !movingFromTheSameCssPageType ) {
    if (loadNewCssPageNames.includes(to.name)) {
      loadCSS('/addTripByImages.css');
      // Remove `general.css` and `queries.css` if previously loaded
      removeCSS('/general.css');
      removeCSS('/queries.css');
    } else {
      loadCSS('/general.css');
      loadCSS('/queries.css');
      // Remove `addTripByImages.css` if previously loaded
      removeCSS('/addTripByImages.css');
    }
  }
  next();
});

function loadCSS(href) {
  // Check if the CSS file is already loaded
  if (!document.querySelector(`link[href="${href}"]`)) {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = href;
    document.head.appendChild(link);
  }
}

function removeCSS(href) {
  // Find and remove the CSS file if it exists
  const link = document.querySelector(`link[href="${href}"]`);
  if (link) {
    document.head.removeChild(link);
  }
}

// import VueGtag from 'vue-gtag'

// Vue.use(VueGtag, {
//   config: { id: config.GOOGLE_ANALITICS_KEY }
// });


// Vue.use(VueAnalytics, {
//   id: config.config.GOOGLE_ANALITICS_KEY,
//   router
// });

// we are using 3.47 becose at 3.48 we have a bug that at  the sumary ride map we are not fit the ,ap to the bounds!!! 
Vue.use(VueGoogleMaps, {
  load: {
    key: config.config.GOOGLE_API_KEY,
    libraries: "places", // necessary for places input,
    language: 'iw',
    v: '3.47'
  }
});
new Vue({
  router,
  store,
  render: h => h(App),
  
  created () {
    firebase.initializeApp(firebaseConfig)
    firebase.auth().useDeviceLanguage()
  }


}).$mount('#app');
