<template>
  <div v-if="userExtraInformation">
    <section class="main">
      <div class="centering_with_gap flex">
        <div class="partial_land">
          <div class="centering_with_gap">
            <div class="flex rem-margin-bottom">
              <div class="main_profile_title">
              
                <div class="profile-image">
                  <img :src="userExtraInformation.profile_image" alt="" class="profile-pic">
                </div>
                <div class="">
                  <h2>{{userExtraInformation.name}}</h2>
                  <span class="title">{{userExtraInformation.title}}</span>
                </div>
              </div>
              <div class="main_profile_icons">
                <!-- existing buttons -->
                <a v-if="userExtraInformation.facebook_link" 
                  :href="userExtraInformation.facebook_link"
                  class="profile_social_icon"
                  target="_blank">
                    <img
                      :src="require('@/assets/images/facebook.svg')"
                      alt="Instagram"
                      height="35px"
                      width="35px"
                    >
                </a> <!-- Instagram Link -->
                <a v-if="userExtraInformation.instagram_link"
                   :href="userExtraInformation.instagram_link"
                   class="profile_social_icon"
                   target="_blank">
                    <img
                      :src="require('@/assets/images/instagram.svg')"
                      alt="Instagram"
                      height="35px"
                      width="35px"
                    >
                </a>  <!-- Facebook Link -->
                <a :href="userExtraInformation.website_link"
                   v-if="userExtraInformation.website_link"
                   class="profile-text-link btn"
                   target="_blank">
                   לאתר
                </a>
              </div>
            </div>
            <div class="user-profile-text">
              <p>{{userExtraInformation.about}}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>

import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'PresentUserInfo',

  computed: mapGetters([
    "userExtraInformation",
  ]),
  methods: {
    ...mapActions(["fetchUserExtarInformationForRide",
                  ]),
  },
    // We moved this one to the creation of the full page to improve SEO
    // created() {
    //   this.fetchUserExtarInformationForRide();
    // },
  };


</script>