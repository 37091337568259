import serchApi from '../../api/serchAttractions';
import { router } from '../../main';

const state = {
  rideManagerAttractions: [],
};

const getters = {
  rideManagerAttractions: state => state.rideManagerAttractions,
};

const actions = {
  async resetAllRideManagerAttractionsData({ commit }) {
    commit('setRideManagerAttractions', []);
  },    

  async fetchRideMangerAttractions({ rootState, commit }) {
    const { apptoken } = rootState.appauth;
    const response = await serchApi.fetchRideMangerAttractions( apptoken );
    commit('setRideManagerAttractions', response.data);

  },
  
  goToAllMyRideManagerAtraction( /* eslint-disable-line no-unused-vars */ _ ) {
      const path = '/ride_manager_attraction';
      router.push( path )
    },
  
};

const mutations = {
  setRideManagerAttractions: (state, rideManagerAttractions) => {
    state.rideManagerAttractions = rideManagerAttractions;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
