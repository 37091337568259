<template>
  <div class="home" >
    <WhatsappChet/>
    <SingleRideHeader  
      presentedText="מלונות מומלצים"
    >
    </SingleRideHeader>
      <div class="trip-card-section after_page_header">
        <div class="container serch_attraction_categories">
          <div class="trip-card-top">
            <!-- TODO we should update this mathod in ordetr to serch the accomodation eriaya  -------------------------------- -->
            <GlobalMapSerchAutoComplet
              @handleserchPointChange="handleUpdateSerchAccommodationPoint" >
            </GlobalMapSerchAutoComplet>
            <a 
              class="trip-btn-bg"
              @click="ReturnToSingleRide()"
              v-if="allRide.ride"
              >
              חזרה לטיול
              ></a>
          </div>
        </div>
      </div>
      <div class="trip-activity-section">
				<div class="container">
          <div class="row">
            <div class="col-xl-7 col-lg-8">
              <div class="trip-sidebar">
                <SearchAccommodations />
              </div>
            </div>
            <div class="col-xl-5 col-lg-4">
              <div class="trip-map-four serch_attraction_map_header">
                <div class="trip-location">
                  <ul>
                    <!-- <li>פעילויות שכבר קיימות בטיול<img src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2FfullColers%2FpointWithFullColler_07A0C3.png?alt=media&token=c3e342d6-f425-495f-842d-c0e6edd2730e" alt=""></li> -->
                    <li>מקום הלינה העליון ברשימה<img src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fmap_letter%2Fblack_numbers%2Fblack_map_Icon_empty.png?alt=media&token=ae5de8aa-cee4-47a7-966b-e02eb1b93480'" alt=""></li>
                  </ul>
                </div>
                <div >
                  <div >
                    <GlobalMap
                    passed_class="single_ride_map">
                    </GlobalMap>
                  </div>
                  <img src="images/map-two.png" alt="">
                </div>
              </div>
            </div>
          </div>
				</div>
      </div>
    <PageFooter />
  </div>
</template>

<script>

import config from '@/common/config';
import PageFooter from '@/components/PageFooter.vue'
import SearchAccommodations from '@/components/SearchAccommodations';
import GlobalMap from '@/components/GlobalMap';
import SingleRideHeader from '@/components/SingleRideHeader';
import GlobalMapSerchAutoComplet from '@/components/GlobalMapSerchAutoComplet';
import WhatsappChet from "@/components/WhatsappChet";
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'SearchAccommodationWithMap',
  components: {
    GlobalMapSerchAutoComplet,
    SearchAccommodations,
    GlobalMap,
    PageFooter,
    SingleRideHeader,
    WhatsappChet,
  },
  computed: mapGetters([  'allRide',
                       ]),
  methods: {
    ...mapActions([ 'updateMarkerByScrollerLocationAtAccommodationSearch',
                    'ReturnToSingleRide',
                    'goToSerchAccommodations',
                    'goToSerchAccommodationsByName',
                     ]),
    handleUpdateSerchAccommodationPoint( place ){
      const lat = place.geometry.location.lat()
      const lng = place.geometry.location.lng()
      this.goToSerchAccommodations({"latitude":lat, "longtitude":lng});
   },
  },
  created(){
    const location_name = this.$route.params.location_name;
    const urlName = this.$route.name;
    if( config.config.ROUTES_URL_NAMES.searchAccommodationsNyName == urlName && location_name){
      this.goToSerchAccommodationsByName(location_name);
    }
    window.addEventListener('scroll', this.updateMarkerByScrollerLocationAtAccommodationSearch);
  },
  mounted(){
    this.$store.commit('setAccommodationPresentedDay', null);
    const sectionHeroElMap = document.querySelector(".serch_attraction_categories");
    const obsMap = new IntersectionObserver(
      function (entries) {
        const ent = entries[0];
        if ( (ent.isIntersecting === false) && ( ent.boundingClientRect.y < 0) ) {
          document.body.classList.add("serch_attraction_map_sticky");
        }
        if (ent.isIntersecting === true) {
          document.body.classList.remove("serch_attraction_map_sticky");
        }
      },
      {
        // In the viewport
        root: null,
        threshold: 0,
        rootMargin: "-40px",
      }
    );
    obsMap.observe(sectionHeroElMap);
  },
  destroyed(){
    window.removeEventListener('scroll', this.updateMarkerByScrollerLocationAtAccommodationSearch);
  },
  metaInfo() {
    return {
      title: 'TripPlanner',
      link: [
        { property: 'og:title', content: 'TripPlanner' },
        { property: 'og:description', content: 'ניתן למצוא מלונות שמטיילים אחרים שיתפו והמליצו' },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `https://tripplanner.co.il/search_accommodations` },
        // { property: 'og:image', content: 'https://tripplanner.co.il/image-url.jpg' },
        { name: 'description', content: 'חיפוש מלונות מומלצים לפי מיקום' },
        // { name: 'keywords', content: 'keyword1, keyword2, keyword3' },
        { rel: 'canonical', href: "https://tripplanner.co.il/search_accommodations" }
      ]
    };
  }
  
};


</script>

<style scoped>

.after_page_header{
  margin-top: 20rem;
}
@media (max-width: 991px) {
  .after_page_header{
    margin-top: 15rem;
  }
}

</style>
