// we should switch the name to page header, many pages are using this content
<template>
<div>
    <NavBar/>

    <div  no-gutters 
          :style="mainHeaderImageStyle"
          class="main_header_image"
    >
      <!-- <NavBar/> -->
      <div
        v-if="presentedText=='RIDE_TITLE' && rideHeader.ride">
        <div class="container">
          <div
            v-if="presentedText=='RIDE_TITLE' && rideHeader.ride"
            class="trip-hero-content">
            <h1 class="trip-header-ride-title">
              {{rideHeader.ride.title}}
            </h1>
            <div
              class="centering_with_gap flex">
              <a
                v-if="rideHeader.ride && !compareToUserId( rideHeader.ride.authorId ) && this.rideType=='public'"
                class="trip-edit-btn"
                @click="addRideToUser(rideHeader.ride.id)">
                <img 
                  :src="require('@/assets/images/editIcon.svg')"
                  width="23px"
                  height="23px"
                  alt=""
                  >
                העתקת טיול ועריכה
              </a>
              <a
                v-else-if="rideHeader.ride && compareToUserId( rideHeader.ride.authorId )"
                class="trip-edit-btn"
                @click="editRide(rideHeader.ride.id)">
                <img 
                  :src="require('@/assets/images/editIcon.svg')"
                  alt=""
                  width="23px"
                  height="23px"
                  >
                עריכת פרטי הטיול
              </a>
              <a
                v-if="rideHeader.ride && compareToUserId( rideHeader.ride.authorId ) && rideHeader.ride.ride_manager_id==userId && this.rideType=='private'"
                class="trip-edit-btn"
                @click="copyPrivateRide(rideHeader.ride.id)">
                <img 
                  :src="require('@/assets/images/editIcon.svg')"
                  width="23px"
                  height="23px"
                  alt=""
                  >
                שכפול הטיול
              </a>
              <a
                v-if="rideHeader.ride && compareToUserId( rideHeader.ride.authorId ) && rideHeader.ride.ride_manager_id==userId && this.rideType=='private'"
                class="trip-edit-btn"
                @click="displayTheRideAcessKey(rideHeader.ride.id)">
                הצגת קישור הרשמה
              </a>

              <a
                v-if="this.rideType=='public'"
                class="trip-share-btn"
                @click="showModal( 'shareRide' )">
                <img
                  :src="require('@/assets/images/shereIcon.svg')"
                  alt=""
                  width="20px"
                  height="24px"
                  >
                שיתוף טיול
              </a>
            </div>
          </div>
        </div>
        <!-- <div
          class="edit-mode-container"
          v-if="rideHeader.ride && compareToUserId( rideHeader.ride.authorId )">
          <h2>מצב עריכה</h2>
        </div> -->
      </div>
      <div
      v-else-if="presentedText && presentedText!='RIDE_TITLE'"
      class="container" >
        <div class="trip-hero-content">
          <h1 class="trip-header-ride-title" > {{presentedText}}   </h1>
        </div>
      </div>
    </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import NavBar from "@/components/NavBar.vue";

export default {
    name: 'SingleRideHeader',
    props: ['presentedText',
            'shouldPresentLocation'],
    data() {
      return {
          mainHeaderImageStyle: {
            background: `url('https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2Fhero-bg.jpg?alt=media&token=170e81d2-ae7e-46eb-b20a-75f0b49fe11d') no-repeat center center`,
          },
      }
    },
    components: {
      NavBar,
  },
    computed: { ...mapGetters([ 'userId',
                                'compareToUserId',
                                'rideHeader',
                                'isMobile',
                                'rideType',
                                'getResizeImageForMobileDevices',
                                 ]),
    },
    methods: { ...mapActions([  'addRideToUser',
                                'copyPrivateRide',
                                'removeDayFromRide',
                                'addNewDayToRide',
                                'removeAttractionFromRide',
                                'validateAndupdateAppToken',
                                'openLoginForm',
                                'showModal',
                                'openRideInformationPage',
                               ]),
    editRide( rideId ){
      this.openRideInformationPage( rideId );
    },
    displayTheRideAcessKey( rideId ){
      this.$router.push('/ride_access_key_information/' + rideId);
    }
  },
  watch: {
    rideHeader(newval) {
      if (newval?.ride?.top_page_image_url) {
        (async () => {
          const imageUrl = this.getResizeImageForMobileDevices(newval.ride.top_page_image_url);
          this.mainHeaderImageStyle = {
            background: `url('${imageUrl}') no-repeat center center`,
            'background-size': 'cover',
          };
        })();
      }
    }
  },

  created() {
    this.validateAndupdateAppToken();
    var resizedUrl = 'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2Fhero-bg.jpg?alt=media&token=170e81d2-ae7e-46eb-b20a-75f0b49fe11d';
    if (this.isMobile){
      resizedUrl = 'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2Fhero-bg_300x300.jpg?alt=media&token=9fdfdb38-6917-421e-a9c4-daa9449819c5';
      this.mainHeaderImageStyle = { background: `url('${resizedUrl}') no-repeat center center`, 'background-size': 'cover', }
    }
  }
}
</script>

<style scoped>
.main_header_image {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    margin-top: 50px;
    min-height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
}

</style>
