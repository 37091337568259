<template>
  <div v-if="show" class="cookie-consent">
    <p>
      🍪 אנו משתמשים בקבצי עוגיות כדי לשפר את חוויית המשתמש, לשמור העדפות ולנתח שימוש באתר.
      בהמשך השימוש באתר, אתה מאשר את השימוש בעוגיות בהתאם למדיניות הפרטיות שלנו.
      <a href="/privacy_policy" target="_blank">קרא עוד</a>
    </p>
    <button @click="acceptCookies">✓ אני מסכים</button>
  </div>
</template>

<script>
export default {
  name: "CookieConsent",
  data() {
    return {
      show: false,
    };
  },
  mounted() {
    const consent = localStorage.getItem("cookie_consent");
    if (!consent) {
      this.show = true;
    }
  },
  methods: {
    acceptCookies() {
      localStorage.setItem("cookie_consent", "true");
      this.show = false;
    },
  },
};
</script>

<style scoped>
.cookie-consent {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background: #f0f0f0;
  padding: 1rem;
  text-align: center;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  z-index: 1000;
}
.cookie-consent p {
  margin: 0 0 0.5rem 0;
}
.cookie-consent button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 4px;
}
.cookie-consent a {
  margin-right: 5px;
  color: #007bff;
  text-decoration: underline;
}
</style>
