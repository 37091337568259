import { render, staticRenderFns } from "./WhatsappChet.vue?vue&type=template&id=ed8533da&scoped=true"
import script from "./WhatsappChet.vue?vue&type=script&lang=js"
export * from "./WhatsappChet.vue?vue&type=script&lang=js"
import style0 from "./WhatsappChet.vue?vue&type=style&index=0&id=ed8533da&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed8533da",
  null
  
)

export default component.exports