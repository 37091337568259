import api from '../../api/manageRidePrivacy';

const state = {
  rideKeyNewRideIdAtCopyRide: null,
  rideKeyValue: null,
  rideKeyInformation: {},
  rideRedirectLink: null,
};


const getters = {
  rideKeyInformation: state => state.rideKeyInformation, 
  rideRedirectLink: state => state.rideRedirectLink,
  rideKeyValue: state => state.rideKeyValue,
  rideKeyNewRideIdAtCopyRide: state => state.rideKeyNewRideIdAtCopyRide,
};


const actions = {
  async resetAllRideKeyData({ commit }) {
    commit('setRideKeyInformation', {});
    commit('setRideRedirectLink', null);
    commit('setRideKeyValue', null);
    commit('setRideKeyNewRideIdAtCopyRide', null);
  },    

  async getRedirectLinkFromRideId({ commit, dispatch } , rideId ) {
    const response = await api.getRedirectLinkFromRideId( rideId );
    if( response.data.ServerMassageCode != "success" ){
      dispatch( 'showModalWithParameters',
      { errorNumber : response.data.ServerMassageCode,
        type:'textConverter' 
      } );
      return 
    }
    commit('setRideRedirectLink', response.data.redirectPage );
    return
  },

  async getAssignedRideKeyInformation({ commit, dispatch } , rideKey ) {
    const response = await api.getAssignedRideKeyInformation( rideKey );
    if( response.data.ServerMassageCode != "success" ){
      dispatch( 'showModalWithParameters',
      { errorNumber : response.data.ServerMassageCode,
        type:'textConverter' 
      } );
      return 
    }
    // the data is:
    // { consulting_meeting_calendar_link:
    //   redirect_page:
    //   ride_id:
    // }
    commit('setRideKeyInformation', response.data.rideData );
    return
  },

  async assignUserToLockedRide({ rootState, dispatch, rootGetters, commit } , rideKeyValue ) {
    const { apptoken } = rootState.appauth;
    const subdomain = rootGetters["theme/getSubdomain"] || "";
    const response = await api.assignUserToLockedRide( apptoken, rideKeyValue, subdomain );
    if( response.data.ServerMassageCode != "success" ){
      dispatch( 'showModalWithParameters',
      { errorNumber : response.data.ServerMassageCode,
        type:'textConverter' 
      } );
      return
    }
    const newRideId = response.data.newRideId
    if( newRideId ){
      commit("setRideKeyNewRideIdAtCopyRide", newRideId );
    }

  },
  async shouldPresentTheDates( { getters }){
    const allRide = await getters.allRide;
    const isItThRideHoner = await getters.compareToUserId( allRide.ride.authorId );
    const endDate = await allRide.ride.end_date;
    let rideEndTime;
    try {
      // Parse "DD-MM-YYYY" format
      const [day, month, year] = endDate.split("-").map(Number);
      rideEndTime = new Date(year, month - 1, day).getTime(); // JS months are 0-indexed
      if (isNaN(rideEndTime)) {
        console.error("Invalid date after parsing:", endDate);
        rideEndTime = 0; // Fallback to avoid runtime errors
      }
    } catch (error) {
      console.error("Error parsing end_date:", error);
      rideEndTime = 0; // Fallback in case of an exception
    }
    if (  getters.rideType === 'private' &&
          !isItThRideHoner  &&
          rideEndTime < Date.now() 
          ) {
        return false;
      }
    return true;
  },


};

const mutations = {
  setRideKeyInformation: (state, rideKeyInformation) => {
    state.rideKeyInformation = rideKeyInformation;
  },
  setRideRedirectLink: (state, rideRedirectLink) => {
    state.rideRedirectLink = rideRedirectLink;
  },
  setRideKeyValue: (state, rideKeyValue) => {
    sessionStorage.setItem('selectedRideKey', rideKeyValue);
    state.rideKeyValue = rideKeyValue;
  },
  setRideKeyNewRideIdAtCopyRide: (state, rideKeyNewRideIdAtCopyRide) => {
    state.rideKeyNewRideIdAtCopyRide = rideKeyNewRideIdAtCopyRide;
  },

};

export default {
  state,
  getters,
  actions,
  mutations
};
