<template>
  <div class="about" style="position: relative">
    <NavBar/>
    <section
      class="d-flex align-items-center"
      style="min-height: calc(100vh - 150px)"
    >
      <b-container>
        <b-row>
          <b-col class="text-right terms-text with-top-bar">
            <h1 class="main-ride-titles">תנאי שימוש באתר TripPlanner</h1>

            <p>
              אתר TripPlanner.co.il הוא פלטפורמה שיתופית לשיתוף טיולים, המלצות ותכנים הקשורים לתכנון טיולים בארץ ובחו"ל. באמצעות העלאת תכנים לאתר, המשתמש מצהיר ומאשר כי כל תוכן שפורסם על ידו באתר חשוף לציבור הרחב ומותר לשימוש חופשי על ידי משתמשים אחרים – לצפייה, השראה או לצורכי תכנון טיולים.
            </p>

            <h3>שיתוף טיולים ותכנים</h3>
            <p>
              מטרת האתר היא לאפשר שיתוף של חוויות טיול עם הקהילה. לכן, כל טיול, תמונה, תגובה או תוכן אחר שמועלה לאתר נחשב תוכן ציבורי. אין להעלות תכנים פרטיים או אישיים שאינך מעוניין שייחשפו.
              המשתמש מוותר מראש על כל דרישה לתמלוגים, הגבלת שימוש או בעלות בלעדית על תכנים שפורסמו על ידו.
            </p>

            <h3>TripPlanner אינו ייעוץ מקצועי</h3>
            <p>
              התכנים באתר נכתבים ונאספים על ידי גולשים ואינם מהווים ייעוץ מקצועי או תחליף לשירות תיירות מוסמך. כל שימוש בתוכן – לרבות המלצות, מסלולים, מקומות לינה ותחבורה – נעשה באחריות המשתמש בלבד.
            </p>

            <h3>שירותים מקצועיים באתר</h3>
            <p>
              לצד תוכן שמועלה על ידי משתמשים פרטיים, TripPlanner מאפשר גם לספקים מקצועיים לשתף טיולים עם לקוחותיהם בלבד. טיולים אלה נגישים רק לקהל הספציפי של הספק (למשל לקוחות בתשלום) ואינם גלויים לציבור הרחב. הספק המקצועי אחראי באופן מלא לתוכן, למידע המוצג ולשירותים הנלווים לטיולים מסוג זה.
            </p>

            <h3>אין התחייבות לשמירת תכנים</h3>
            <p>
              החברה שומרת לעצמה את הזכות למחוק, לשנות או לאחסן מחדש תכנים לפי שיקול דעתה, לרבות תכנים שהוזנו על ידי משתמשים. אין התחייבות לשמירה קבועה של תכנים או מידע אישי, והמשתמש מאשר כי הוא שומר גיבוי לתוכן שברצונו לשמר.
            </p>

            <h3>תכנים שיווקיים ופרסום</h3>
            <p>
              ייתכן וחלק מהתכנים באתר כוללים מידע שיווקי, פרסומות, או תכנים ממומנים. כל פרסום הוא באחריות המפרסם בלבד, והאתר אינו צד לעסקאות שייחתמו בעקבות פרסום זה.
            </p>

            <h3>הגבלת אחריות</h3>
            <p>
              המידע באתר ניתן כמות שהוא (AS IS). האתר אינו אחראי לדיוק, שלמות או עדכניות המידע, ואינו מתחייב לכך שהשימוש באתר יתאים לצרכיך.
              האחריות לשימוש בתכנים היא על המשתמש בלבד. TripPlanner לא תישא באחריות לכל נזק ישיר או עקיף שייגרם כתוצאה משימוש באתר או מהסתמכות על תכנים בו.
            </p>

            <h3>שימוש באתר</h3>
            <p>
              אין לעשות באתר שימוש לצרכים מסחריים, פוגעניים, בלתי חוקיים או כאלה שמפרים את פרטיותם או זכויותיהם של אחרים. החברה שומרת לעצמה את הזכות להסיר תכנים ו/או לחסום משתמשים ללא צורך בהודעה מוקדמת.
            </p>

            <h3>זכויות יוצרים וקניין רוחני</h3>
            <p>
              כל הזכויות על עיצוב האתר, הלוגו, מערכת ההפעלה והקוד שמרכיבים את TripPlanner שמורות לחברה בלבד. אין להעתיק, לשכפל, או לעשות כל שימוש מסחרי ללא אישור בכתב.
            </p>

            <h3>שינויים באתר ובתקנון</h3>
            <p>
              החברה רשאית לעדכן מעת לעת את תנאי השימוש, את מבנה האתר, התכנים והשירותים בו, או את מדיניות הפרטיות – לפי שיקול דעתה הבלעדי וללא הודעה מוקדמת.
            </p>

            <h3>פנייה אלינו</h3>
            <p>
              בכל שאלה או בקשה ניתן לפנות אלינו בדוא"ל:
              <a href="mailto:avshalom@tripplanner.co.il">avshalom@tripplanner.co.il</a>
            </p>

            <h4 style="margin-top: 3rem;">עודכן לאחרונה: מרץ 2025</h4>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <PageFooter />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PageFooter from "@/components/PageFooter.vue";
import NavBar from "@/components/NavBar.vue";

export default {
  name: "TermsOfUse",
  components: {
    NavBar,
    PageFooter,
  },
  computed: mapGetters(['globalRides', 'userId']),
};
</script>

<style scoped>
.with-top-bar {
  margin-top: 50px;
}

.terms-text {
  font-style: normal;
  font-size: 1.5rem;
  line-height: 2.2rem;
  letter-spacing: 0.03em;
  color: #212529;
}
.main-ride-titles {
  color: #07A0C3;
  margin-bottom: 2rem;
}
.terms-text h3 {
  font-weight: bold;
  color: #07A0C3;
  margin-top: 2rem;
}
.terms-text a {
  color: #007bff;
  text-decoration: underline;
}
</style>