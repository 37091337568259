import Vuex from 'vuex';
import Vue from 'vue';
import auth from './modules/auth';
import screanSize from '../common/screanSize';
import mapIcons from '../common/mapIcons';
import plaseInformation from '../common/plaseInformation';
import imageURLSizeManipulation from '../common/imageURLSizeManipulation'
import images from './modules/images';
import ride from './modules/ride';
import presentUserInfo from './modules/presentUserInfo';
import labelNameHolder from './modules/labelNameHolder';
import attractionUniqnes from './modules/attractionUniqnes';
import accommodations from './modules/accommodations';
import searchAccommodations from './modules/searchAccommodations';
import searchAccommodationsWithMap from './modules/searchAccommodationsWithMap';
import serchAttractions from './modules/serchAttractions';
import rideManagerAttractions from './modules/rideManagerAttractions';
import serchAttractionsWithMap from './modules/serchAttractionsWithMap';
import theme from './modules/theme';
import appauth from './modules/appauth';
import manageRidePrivacy from './modules/manageRidePrivacy';
import singleMapRide from './modules/singleMapRide';
import globalMap from './modules/globalMap';
import commonMap from './modules/commonMap';
import createAttraction from './modules/createAttraction';
import uploadTrip from './modules/uploadTrip';
import accommodation from './modules/accommodation';
import userRides from './modules/userRides';
import rideInformation from './modules/rideInformation';
import extraInformationForRide from './modules/extraInformationForRide';
import globalRides from './modules/globalRides';
import PagePopups from './modules/popups';
import editRideAccessKey from './modules/editRideAccessKey';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    screanSize,
    mapIcons,
    plaseInformation,
    imageURLSizeManipulation,
    images,
    ride,
    presentUserInfo,
    labelNameHolder,
    attractionUniqnes,
    appauth,
    manageRidePrivacy,
    singleMapRide,
    searchAccommodations,
    searchAccommodationsWithMap,
    serchAttractions,
    serchAttractionsWithMap,
    rideManagerAttractions,
    theme,
    globalMap,
    commonMap,
    createAttraction,
    editRideAccessKey,
    uploadTrip,
    accommodation,
    accommodations,
    userRides,
    rideInformation,
    extraInformationForRide,
    globalRides,
    PagePopups,
  }
});
