<template>
  <div id="app">
    <router-view/>
    <CookieConsent />
    <PagePopups/>
  </div>
</template>

  <!-- The core Firebase JS SDK is always required and must be listed first -->
<script src="/__/firebase/8.2.5/firebase-app.js"></script>

<!-- TODO: Add SDKs for Firebase products that you want to use
      https://firebase.google.com/docs/web/setup#available-libraries -->
<script src="/__/firebase/8.2.5/firebase-analytics.js"></script>
<!-- Initialize Firebase -->
<script src="/__/firebase/init.js"></script>
<script>
import NavBar from "@/components/NavBar.vue";
import PagePopups from "@/components/Popups.vue";
import CookieConsent from "@/components/CookieConsent.vue";

export default {
  name: 'App',
  components: {
    NavBar,
    PagePopups,
    CookieConsent,
  },
  created() {
    this.$store.dispatch("theme/detectSubdomain");
  },
}
</script>

<style>
/* body {
  font-family: Poppins, sans-serif;
} */
.btn-warning {
  background-color: #ffb600;
  border-color: #ffb600;
  color: #ffffff;
}
.btn-warning:hover,
.btn-outline-warning:hover {
  color: #ffffff;
  background-color: #ffb600;
  border-color: #ffb600;
}
.btn-warning,
.btn-outline-dark {
  font-weight: bold;
  font-size: 16px;
  line-height: 126.3%;
  letter-spacing: 0.045em;
  border-radius: 50px;
}
.h2 {
  font-size: 30px;
  line-height: 39px;
  font-weight: bold;
  color: #0d2f29;
}
.turquoise {
  color: #34d0b6;
}
.modal-header {
  border-bottom: 0 solid #e9ecef;
}
.close:focus,
.close:hover {
  color: rgba(0, 0, 0, 0.9);
  outline: 0;
  background-color: transparent;
}
.text-warning {
  color: #ffb600;
}
</style>
