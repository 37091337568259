import api from '../../api/singleride';
import editApi from '../../api/editRide';
import moment from 'moment';
import { router } from '../../main';

const state = {
  ride: {},
  rideId: null,
  rideHeader: {},
  lowedRideId: null,
  attractionForChangeingTheDate: null,
  newAttractionDateTime: null,
  rideUploaded: false,

};

const getters = {
  allRide: state => state.ride,
  rideId: state => state.rideId,
  rideHeader: state => state.rideHeader,
  lowedRideId: state => state.lowedRideId,
  attractionForChangeingTheDate: state => state.attractionForChangeingTheDate,
  newAttractionDateTime: state => state.newAttractionDateTime,
  rideUploaded: state => state.rideUploaded,
  rideType: state => {
    return typeof state?.ride?.ride?.ride_manager_id === 'number' ? 'private' : 'public';
  },
  rideTypeIsPrivate: state => {
    return typeof state?.ride?.ride?.ride_manager_id === 'number' ? true : false;
  },
  rideIsWhiteLabel: state => {
    return state?.ride?.ride?.is_white_label === true ? true : false;
  }
};

const actions = {
  cleanAllRideInformation({commit}){ 
    commit('setRide', {}); 
    commit('setRideHeader', {}); 
    commit('setLowedRideId', null); 
    commit('setAttractionForChangeingTheDate', null); 
    commit('setNewAttractionDateTime', null); 
    commit('setAttractionRideUploaded', false); 
  },

  updateAttractionRideUploadedWorkAroundInOrderToDisplayTheMainRideAfterFulyLoaded({commit}){
    commit('setAttractionRideUploaded', true);
  },
  async fetchRideWithLoadingPopup({ dispatch }) {
    dispatch( 'showModal', "loadingIcon" );
    await dispatch( 'fetchRide');
    dispatch( 'showModal', "" );
  },
  async fetchRideAndCalback({ rootState, state, commit, dispatch }) {
    const { apptoken } = rootState.appauth;
    const lowedRideId = state.lowedRideId;
    const response = await api.fetchRide(apptoken, lowedRideId);
    if( response.data.ServerMassageCode != "success" ){
      // we are popuping the error at the fetchRideHeaderInformation tha hapand befor
      return
    }
    commit('setRide', response.data);
    dispatch('buildAccommodationIcons');
    dispatch('updateTheRideUploadedWorkAroundInOrderToDisplayTheMainRideAfterFulyLoaded');
    
    return response
  },
  async updateTheRideUploadedWorkAroundInOrderToDisplayTheMainRideAfterFulyLoaded({commit}){
    return new Promise(resolve => {
      setTimeout(() => {
        commit('setAttractionRideUploaded', true);
        resolve(); // Resolve the promise after the delay
      }, 4000); // 1000 milliseconds = 1 second
    });



    // commit('setAttractionRideUploaded', true);
  },
  async fetchRideHeaderInformation({ rootState, state, commit, dispatch }) {
    const { apptoken } = rootState.appauth;
    const lowedRideId = state.lowedRideId;
    const response = await api.fetchRideHeader(apptoken, lowedRideId);
    if( response.data.ServerMassageCode != "success" ){
      if(response.data.ServerMassageCode == "user_does_not_have_permission_to_this_private_ride"){
        router.push('/private_page_landing/' + lowedRideId );
        return
      }
      dispatch( 'showModalWithParameters',
      { errorNumber : response.data.ServerMassageCode,
        type:'textConverter' 
      } );
      return
    }
    commit('setRideHeader', response.data);
    return response
  },
  async fetchRide({ dispatch }) {
    var response = await dispatch('fetchRideAndCalback');
    if( !response){
      return
    }
    for( var i=0; i < response.data.attractionsByDay.length; i++ ){
      var attractionpoints = await dispatch( 'updatemarkerByColers', response.data.attractionsByDay[i].daily_attractions );
      response.data.attractionsByDay[i].daily_attractions = attractionpoints
    }

    var highLevelRideLocations = await dispatch( 'updatemarkerByColersAndLetter', response.data.highLevelRideLocations );
    // if it is empty we van not do the next step
    dispatch( 'addMap', { elementId:"map_canvas",
                          points: highLevelRideLocations });
  },
  updateMainRideScroleLocation( /* eslint-disable-line no-unused-vars */ _ ){
    localStorage.setItem('workAroundToSetTheScroleLocationAtTheMAinPage', window.top.scrollY);
  },
  userGuideForEditing( {dispatch, getters} ){
    // window.localStorage.removeItem('dont_display_user_guide_for_editing_massage');
    // TODO -------- thsi one is not removed after getin to y=2000
    const rideType = getters.rideType;
    var guidePresented = window.localStorage.getItem('dont_display_user_guide_for_editing_massage')
    if( guidePresented || rideType != 'public'){
      window.removeEventListener('scroll', this.userGuideForEditing);
      return
    }
    if( window.top.scrollY > 2000 && !guidePresented ){
      dispatch( 'showModalWithParameters',
                { errorNumber : "user_guide_for_editing",
                  type:'textConverter' 
                } );
      window.localStorage.setItem('dont_display_user_guide_for_editing_massage', true);
    }
  },
  async uploadRide({ rootState }, ride) {// this one is not in use!!!!!!!!!
    // Get the access token
    const { apptoken } = rootState.appauth;

    // Call our API module to do the upload
    await api.uploadRide(ride, apptoken);

    // Redirect our user to ImageList component
    router.push('/');
  },
  ReturnToSingleRide({ rootState }) {
    const rideId = rootState.ride.rideId;
    router.push('/single_ride_with_map/' + rideId );
  },
  async removeDayFromRide({ rootState, dispatch }, dayIndex ) {
    const { apptoken } = rootState.appauth;
    const rideId = rootState.ride.rideId;
    const response = await editApi.removeDayFromRide( apptoken, rideId, dayIndex );
    if( response.data.ServerMassageCode != "secsses" ){
      dispatch( 'showModalWithParameters',
                { errorNumber : response.data.ServerMassageCode,
                  type:'textConverter' 
                } );
    }
    await dispatch('fetchRide');
  },
  async addNewDayToRide({ rootState, dispatch }, dayIndex ) {
    const rideId = rootState.ride.rideId;
    const { apptoken } = rootState.appauth;
    const response = await editApi.addNewDayToRide( apptoken, rideId, dayIndex);
    if( response.data.ServerMassageCode != "secsses" ){
      dispatch( 'showModalWithParameters',
                { errorNumber : response.data.ServerMassageCode,
                  type:'textConverter' 
                } );
    }

    await dispatch('fetchRide');
  },

  async updateRideDuration({ rootState, dispatch }, newRideDates ) {
    const rideId = rootState.ride.rideId;
    const { apptoken } = rootState.appauth;
    const response = await editApi.updateRideDuration( apptoken, rideId, newRideDates);
    if( response.data.ServerMassageCode != "secsses" ){
      dispatch( 'showModalWithParameters',
                { errorNumber : response.data.ServerMassageCode,
                  type:'textConverter' 
                } );
    }
    await dispatch('fetchRide');
  },
  async getTheClosestAttractionStartFromGoingDown( { rootState },dayIndex ){
    const ride = rootState.ride;
    while( (ride.ride.attractionsByDay[dayIndex].daily_attractions.length == 0) && ( dayIndex > 0 )){
      dayIndex = dayIndex - 1;
    }
    // serche after the date if not exist
    if( ride.ride.attractionsByDay[dayIndex].daily_attractions.length == 0 ){
      while(  (ride.ride.attractionsByDay[dayIndex].daily_attractions.length == 0) &&
              ( ( ride.ride.attractionsByDay.length - 1 ) > dayIndex ) ) {
        dayIndex = dayIndex + 1;
      }
    }
    const dylyactivity = ride.ride.attractionsByDay[dayIndex]
    var i;
    var lastLng = 0;
    var lastLat = 0;
    for( i = 0; i < dylyactivity.daily_attractions.length; i++ ){
      if( dylyactivity.daily_attractions[i].latitude ){
        lastLat = dylyactivity.daily_attractions[i].latitude;
        lastLng = dylyactivity.daily_attractions[i].longtitude;
      }
    }
    return{ 'lat': lastLat, 'lng':lastLng }

  },
  async addhttp( _, url ) {
    // We have an RIGHT-TO-LEFT MARK at the end of the string ( at some links )
    // and we should remove them!
    var statickUrl = await url;
    if ( !/^(?:f|ht)tps?:/i.test( statickUrl ) ){
      statickUrl =  'http://'.concat( statickUrl )
    }
    // return statickUrl.replace(/[^\x00-\x7F]/g, "");
    return statickUrl.replace(/[^\x20-\x7F]/g, "");
  },

  async serchAttractionToRide({ rootState, commit, dispatch }, dayIndex ) {
    const ride = rootState.ride
    const originalDayIndex = dayIndex;
    const point =  await dispatch('getTheClosestAttractionStartFromGoingDown', dayIndex );
    
    var lat = point.lat;
    var lng = point.lng;
    if( ride.ride.attractionsByDay[originalDayIndex].daily_attractions.length == 0 ){
      if( ride.ride.attractionsByDay[originalDayIndex].accommodation.latitude ){//                 wehave an booking information for this morning)){
        lat = ride.ride.attractionsByDay[originalDayIndex].accommodation.latitude
        lng = ride.ride.attractionsByDay[originalDayIndex].accommodation.longtitude
      }
      else if( ride.ride.attractionsByDay[originalDayIndex].base_trip_accommodation.latitude ){//    wehave an booking information for this morning)){
        lat = ride.ride.attractionsByDay[originalDayIndex].base_trip_accommodation.latitude
        lng = ride.ride.attractionsByDay[originalDayIndex].base_trip_accommodation.longtitude
      }
    }

    var palannedDate = new Date(ride.ride.attractionsByDay[originalDayIndex].dateTime);
    palannedDate.setHours(23,59);
    commit('setSerchPlannedDate', palannedDate.toLocaleString("en-US"));
    commit('setSerchLat', lat);
    commit('setSerchLng', lng);
    commit('setSerchDayIndex', originalDayIndex);
    router.push('/serch_attractions_with_map');
  },
  async removeAttractionFromRide( { rootState, dispatch }, attractionId){
    const rideId = rootState.ride.rideId;
    const { apptoken } = rootState.appauth;
    await editApi.removeAttractionFromRide( apptoken, rideId, attractionId );
    await dispatch( "fetchRide" );
  },
  async updateAttractionTimeAtRide( { dispatch, rootState, state }){
    const rideId = rootState.ride.rideId;
    const attractionId = state.attractionForChangeingTheDate.id
    const attractionNewTime = state.newAttractionDateTime
    const { apptoken } = rootState.appauth;
      await editApi.updateAttractionRideTime(  apptoken,
                                               rideId,
                                               attractionId,
                                               attractionNewTime );
    router.push('/single_ride_with_map/' + rideId );
    dispatch('fetchRideAndCalback');
  },
};

const mutations = {
  setRide: (state, ride) => {
    state.ride = ride;
    if( ride.ride ){
      state.rideId = ride.ride.id;
    }
    else {
      state.rideId = null
    }
  },
  setRideHeader: (state, rideHeader) => {
    state.rideHeader = rideHeader;
  },
  setLowedRideId: (state, lowedRideId) => {
    state.lowedRideId = lowedRideId;
  },
  setAttractionForChangeingTheDate: (state, attractionForChangeingTheDate) => {
    if( attractionForChangeingTheDate ){
      state.attractionForChangeingTheDate = attractionForChangeingTheDate;
      const originalDateTime = new Date(state.attractionForChangeingTheDate.dateTime.replace('GMT','') );
      state.newAttractionDateTime = moment(originalDateTime).format('M/D/YYYY, hh:mm:ss A');
    }
    else{
      state.attractionForChangeingTheDate = attractionForChangeingTheDate
      state.newAttractionDateTime = null
    }
  },
    setNewAttractionDateTime: (state, newAttractionDateTime) => {
    state.newAttractionDateTime = newAttractionDateTime;
  },
  setNewAttractionDate: (state, newAttractionDate) => {
    state.newAttractionDateTime = newAttractionDate + ", " + state.newAttractionDateTime.split(",")[1];
  },
  setNewAttractionTime: (state, newAttractionTime) => {
    state.newAttractionDateTime = state.newAttractionDateTime.split(",")[0] + ", " + newAttractionTime;
  },
  setAttractionRideUploaded: ( state, rideUploaded ) => {
    state.rideUploaded = rideUploaded;
  } 
};

export default {
  state,
  getters,
  actions,
  mutations
};
