import api from '../../api/userRides';

const state = {
  userRides: null,
};

const getters = {
  userRides: state => state.userRides,
};

const actions = {
  async fetchUserRides({ rootState, commit }) {
    const { apptoken } = rootState.appauth;
    const response = await api.fetchUserRides( apptoken );
    commit('setUserRides', response.data);
  },
  async addRideToUser({ rootState, commit, dispatch }, rideId) {
    const { apptoken } = rootState.appauth;
    if( apptoken ){
      const response = await api.addRideToUser( apptoken, rideId );
      commit('setUserRides', response.data);
      const NewRideId = response.data.newRideId
      dispatch( 'openRideInformationPage', NewRideId );
      
      // console.log( "we are so hapy that you are editing this ride!!!!")
      dispatch( 'showModalWithParameters',
      { errorNumber : 'uesr_copyed_new_ride',
        type:'textConverter' 
      } );

    }
    else{
      dispatch( 'showModal', "pleaseLogInInOrderToEditRide" );
    }
  },
  async copyPrivateRide({ rootState, commit, dispatch }, rideId) {
    const { apptoken } = rootState.appauth;
    if( apptoken ){
      const response = await api.copyPrivateRide( apptoken, rideId );
      commit('setUserRides', response.data);
      const NewRideId = response.data.newRideId
      dispatch( 'openRideInformationPage', NewRideId );
    }
  },

};

const mutations = {
  setUserRides: (state, rides) => {
    state.userRides = rides;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
