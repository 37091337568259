<template>
<div>
  <b-modal
    id="attraction"
    hide-footer
    centered
    v-model="isVisible"
    modal-class="modal-cover "
    content-class="shadow-lg"
  >
    <template  #modal-header="{ close }">
      <!-- Emulate built in modal header close button action -->
      <div class="image-container">
        <img
          v-if="isNewAttractionImage"
          class="bg-image"
          :src="newAttractionImage">
      </div>
      <b-button size="sm" @click="close()">
        X
      </b-button>
      <star-rating
        v-if="isNewAttractionRate && !isSubdomain"
        class="star-custom-text"
        :read-only="true"
        :rtl="true"
        :increment="0.01"
        :star-size="25"
        :border-width="3"
        :rating=newAttractionRate
        />
      <!-- <h5>Modal Header</h5> -->
    </template>

    <div>
      <div class="attraction-clear-image">
        <img 
        align="left"
        :src="newAttractionImage"
      />
      </div>
      <b>
        <h3 class="font-weight-medium card-title">{{newAttractionTitle}}</h3>
      </b>
      <p class="usingNewLine" >
        {{attractionText}}
      </p>
      <br>
      <b-link
        v-if="newAttractionLink"
        :href="newAttractionLink"
        target="_blank"
        class="clikable d-inline-flex align-items-center attraction-button-size primary rounded-pill fs-6"
      >
        <img  
          width="23px"
          height="18px"
          alt="linkAttractionIcon"
          class="ms-2 attraction-button-image"
          :src="linkAtttractionIcon()"
        >
        לאתר 
      </b-link>


  </div>
  <div v-if="newAttractionOpeningHours" class="centering-with-start">
    <div>
      <h3 class="bold" >
        שעות פעילות:
      </h3>
      <p v-for="(oneDayOpenHours, index ) in newAttractionOpeningHours"
      :key="index">
          {{oneDayOpenHours}}
      </p>
    </div>
  </div>
    <div 
      class="stretching"
      v-if="isNewAttractionReview && !isSubdomain">
        <h3 class="bold" >
          ביקורות על המקום:
        </h3>
      <div  v-for="(review, index ) in newAttractionReview"
          class="attraction_review"
          :key="index">
        <p>
          {{review.author_name}}

        </p>
        <star-rating
          :v-if=review.rating
          :read-only="true"
          :rtl="true"
          :increment="0.01"
          :star-size="15"
          :rating=review.rating
          />
        <p>
          {{review.text}}
        </p>
      </div>
    </div>
    <button class="fixed_poup_close_me" block @click="$bvModal.hide('attraction')">X</button>
  </b-modal>
</div>
</template>
<script>

import { mapGetters } from "vuex";
import StarRating from 'vue-star-rating'
import config from '@/common/config';
export default {
props: ["value"],
  name: "AtractionPopUp",
  components: {
    StarRating
  },

  computed: {
    ...mapGetters([ 'allRide',
                    'newAttractionTitle',
                    'newAttractionAddress',
                    'newAttractionText',
                    'newAttractionLink',
                    'newAttractionLat',
                    'newAttractionLng',
                    'newAttractionImage',
                    'isNewAttractionImage',
                    'newAttractionpreviewImage',
                    'isNewAttractionEditMode',
                    'newAttractionOpeningHours',
                    'newAttractionReview',
                    'newAttractionRate',
                    'isNewAttractionRate',
                    'isNewAttractionReview' ]),
    ...mapGetters("theme",  [ "isSubdomain",
                        ]),

    attractionText:{
        get () {
          return this.newAttractionText
        },
        set (value) {
          this.$store.commit('setNewAttractionText', value)
        }
      },

    isVisible: {
        set(val) {
            this.$emit("input", val);
        },
        get() {
            return this.value;
        },
        },
    },


methods:{
    linkAtttractionIcon(){
      return config.config.LINK_ATTRACTION_ICON
    },
    getLinkUrl(){
      if ( this.allRide && this.allRide.ride && this.allRide.ride.id ){
        return ( "http://tripplanner.co.il/single_ride_with_map/" + this.allRide.ride.id )
      }
      return "http://tripplanner.co.il/single_ride_with_map/"
    },
    getWhatsappUrl(){
      if ( this.allRide && this.allRide.ride && this.allRide.ride.id ){
        return "https://web.whatsapp.com/send?text=http://tripplanner.co.il/single_ride_with_map/" + this.allRide.ride.id
      }
      return "https://web.whatsapp.com/send?text=http://tripplanner.co.il/single_ride_with_map/"
    },

  },

};
</script>
<style scoped>
.modal.modal-cover .modal-content,
.modal.modal-cover .modal-footer,
.modal.modal-cover .modal-header {
  border: none;
  align-items: stretch;
}

p{
  letter-spacing: 0.045em;
  line-height: 1.4;
  font-size: 1.8rem;


}

.attraction-clear-image img {
  position: relative;
  padding-right: 3rem;
  /* top: -5rem; */
  /* right: -2rem; */
  width: 20rem;
}

 @media (max-width: 734px) {
  .attraction-clear-image img {
    display: none;
  } 
}

.image-container{
  min-height: 10rem;
  width: 100%;
  padding-bottom: 1rem;

}

.modal-header button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  /* height: 1rem; */
  background: white;
  color: black;
}

.modal-header .vue-star-rating {
  position: absolute;
  top: 7rem;
  right: 1rem;
  color: black;
  /* height: 1rem; */
  /* background: white; */
}


.height-50 {
  height: 50px !important;
}
.width-50 {
  width: 50px !important;
}

.image-and-conntent{
  display: grid;
  margin-right: 2rem;
  grid-template-columns: auto 22rem;

}

.attraction_review{
  margin: 1rem 2rem;
  border-bottom: .5px solid;
  /* justify-items: stretch; */

}








</style>


















