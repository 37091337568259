<template>
  <div>
    <b-modal
      id="dynamicModal"
      modal-class="modal-cover"
      v-model="isVisible"
      hide-backdrop
      hide-footer
      content-class="shadow-lg"
    >
        <div 
          v-if="dynamicModal"
          class="text-center">
          <h3 class="h4 font-weight-bold">{{dynamicModal.text}}</h3>
          <p class="h7 text-muted">{{dynamicModal.subText}}</p>
          <div class="mt-3">
            <div
              v-if="dynamicModal.spionr"
              class="spinner-border ml-auto"
              role="status">
            </div>
            <b-button 
              v-else
              class="btn btn-warning px-5 py-3 rounded-pill fw-semibold h5 font-weight-bold z-2"
              @click="handle_function_call(dynamicModal.action)"
              >
              {{dynamicModal.actionText}}
            </b-button>
          </div>
        </div>

      
      
      
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import config from '@/common/config';

export default {
  props: ["value"],
  computed: {
    ...mapGetters([ 'allRide',
                    'dynamicModal'
                  ]),
    isVisible: {
      set(val) {
        this.$emit("input", val);
      },
      get() {
        return this.value;
      },
    },
  },
  methods: { 
    ...mapActions([ 'addRideToUser',
                    'openLoginForm',
                    'showModal',
     ]),
    closePopup(){
      this.showModal('')
    },
    goToAddRideWithAlboumDetailesForm(){
      window.open(config.config.GOOGLE_FORM_ADD_NEW_RIDE_WITH_ALBUM, '_blank')
      this.showModal('')
    },
    openLoginFormPresed(){
      this.showModal('')
      // this.$store.commit("setRideInformationId", rideId);
      // this.value = false;
      this.openLoginForm()
    },
    handle_function_call(function_name) {
      this[function_name]()
    },
  },
};
</script>



<style scoped>
.modal.modal-cover .modal-content,
.modal.modal-cover .modal-footer,
.modal.modal-cover .modal-header {
  border: none;
}
.height-50 {
  height: 50px !important;
}
.width-50 {
  width: 50px !important;
}
.sub_text_popup{
  font-size:15px;
}

h3 {
    font-size: 24px;
    line-height: 1.7;
    font-weight: 700 !important;
    color: #1e1e1e !important;
}

.h7 {
  font-size: 16px
}

</style>