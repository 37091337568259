<template>
  <div>
    <SingleRideHeader
      presentedText="הטיולים שלי"
    />

    <b-container class="py-5 after_page_header">
      <b-row>
        <b-col cols="12" md="8" offset-md="4" class="pt-5">
          <div
            v-if="isUserManager"
            class="d-flex justify-content-between align-items-center">
            <b-btn @click="addRideManualy()" pill variant="warning" class="px-5 py-3 text-white"
              >יצירת מסלול לשיתוף</b-btn
            >
            <b-btn @click="goToAllMyRideManagerAtraction()" pill variant="warning" class="px-5 py-3 text-white"
              >כל הפעילויות שלי</b-btn
            >
 
          </div>
          <div 
            v-else
            class="d-flex justify-content-between align-items-center">
            <b-btn @click="openRideInformationPage()" pill variant="warning" class="px-5 py-3 text-white"
              >שיתוף טיול באתר</b-btn
            >
          </div>
        </b-col>
      </b-row>
    </b-container>

    <b-container class="py-5">
      <b-row>
        <b-col>
          <b-card
            no-body
            class="mb-5 border-0"
            v-for="(userRide, index) in userRides"
            :key="index"
          >
            <!-- visible md and up -->
            <!-- <h1>{{userRide}}</h1> -->
            <div class="d-none d-md-flex">
              <b-row class="align-items-center w-100">
                <b-col>
                  <div>
                    <b-row>
                      <b-col class="text-right py-3">
                        <div  dir="rtl" >
                          <span style="white-space: pre-wrap;"><h4>{{ userRide.title }}</h4></span>
                          <span style="white-space: pre-wrap;"><p>{{ userRide.text }}</p></span>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col class="text-right py-3">
                        <p class="d-inline mr-4">{{ userRide.start_date }} - {{ userRide.end_date }}</p>
                        <img
                          :src="require('@/assets/images/icons/icons8-edit-calendar-50.png')"
                        />
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col class="text-right py-3">
                        <b-btn
                          v-if="userRide.ride_manager_id"
                          @click="goToRide(userRide.id)"
                          pill
                          variant="warning"
                          class="px-5 py-3 text-white"
                          >צפיה בטיול הפרטי</b-btn
                        >
                        <b-btn
                          v-else
                          @click="goToRide(userRide.id)"
                          pill
                          variant="warning"
                          class="px-5 py-3 text-white"
                          >צפיה ועריכת הטיול</b-btn
                        >
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
            </div>
            <!-- visible only on small screens -->
            <!-- <div class="d-flex d-md-none text-center pt-5"> -->
            <div class="d-flex d-md-none">
              <div>
                <b-row>
                  <b-col class="text-right py-3">
                    <h4>{{ userRide.title }}</h4>
                    <p>{{ userRide.text }}</p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="text-right py-3">
                    <p class="d-inline mr-4">{{ userRide.start_date }} - {{ userRide.end_date }}</p>
                    <img :src="require('@/assets/images/icons/icons8-edit-calendar-50.png')" />
                  </b-col>
                </b-row>
                <b-row>
                </b-row>
                <b-row>
                  <b-col class="text-right py-3">
                    <b-btn
                      @click="goToRide(userRide.id)"
                      pill
                      variant="warning"
                      class="px-5 py-3 text-white"
                      >צפיה ועריכה של טיול זה</b-btn
                    >
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>


<script>
import { mapActions, mapGetters } from 'vuex';
import SingleRideHeader from "@/components/SingleRideHeader";

export default {
  name: 'UserRidesV',
  computed: mapGetters(['userRides',
                        'isAppLoggedIn',
                        'userId',
                        'isUserManager',
                        ]),

  components: {
    SingleRideHeader,    

  },
    methods: { ...mapActions([  'fetchUserRides',
                                'removeAttractionFromRide',
                                'validateAndupdateAppToken',
                                'openRideInformationPage',
                                'goToAllMyRideManagerAtraction',
                              ]),
    addRideManualy( ){
      this.$router.push('/ride_information' )
    },

    curentuserIsTheRideAuthor( attractionAuthorId ){
      if( this.userId == attractionAuthorId ){
        return true;
        }
      return false
    },

    goToRide( rideId ) {
      this.$router.push('/single_ride_with_map/' + rideId )
    }
  },
  created() {
    this.validateAndupdateAppToken();
    this.fetchUserRides();
  },
  metaInfo() {
    return {
      title: 'TripPlanner',
      link: [
        { property: 'og:title', content: 'TripPlanner' },
        { property: 'og:description', content: 'רשימת המסלולים שבחרתי או שהעלתי לאתר' },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `https://tripplanner.co.il/user_rides` },
        // { property: 'og:image', content: 'https://tripplanner.co.il/image-url.jpg' },
        { name: 'description', content: 'רשימת המסלולים שלי' },
        // { name: 'keywords', content: 'keyword1, keyword2, keyword3' },
        // { rel: 'canonical', href: "https://tripplanner.co.il/user_rides" }
      ]
    };
  }
};
</script>

<style scoped>

.after_page_header{
  margin-top: 15rem;
}

</style>