<template>
<div class="position-relative overflow-hidden" dir="ltr">
    <AppNavbar/>
    <div>
      <img
        alt=""
        class="background-image"
        src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fcircles-background.png?alt=media&token=d420088e-66c6-4c95-bd3b-2f218dd36aba">
    </div>
    <ImageBanner :backgroundImage= "'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fride-info-banner.jpg?alt=media&token=b765e21f-2604-4874-a029-3453357e4897'"
                 paragraph="ניתן להעתיק את הקישור ולשלוח ללקוח"
                 size="240px" title="פרטי הגישה למסלול הפרטי"/>
  <b-container>
    <b-row class="justify-content-center">
      <b-col col md="6">
        <b-form>
          <b-form-group
            v-if="isUserSubdomainManager"
            id="label-details"
            class="text-right justify-content-end d-flex align-items-center"
            label="לאפשר למשתמשים לערוך את הטיול שלהם"
            label-for="checkbox-1">
          <b-form-checkbox
              id="checkbox-1"
              class="checkbox-right ml-2 checkbox-custom mb-2"
              name="checkbox-1"
              size="lg"
              v-model="localRideAccesskeyActionIsEdit"
              :unchecked-value=false
              :value=true>
          </b-form-checkbox>
          </b-form-group>
          <b-form-group
              id="label-title"
              class="text-right"
              label="לינק הרשמה למסלול"
              label-for="title">
            <b-form-input
                v-model="localRideAccesskeySecretKey"
                id="title"
                class="form-control border-primary rounded-2 text-right py-4"
                placeholder=""
                required
                type="text"
                readonly>
            </b-form-input>
            <b-button @click="copyLink" variant="primary" class="mt-2">
              העתקת הקישור
            </b-button>
          </b-form-group>
          <b-form-group
              id="label-title"
              class="text-right"
              label="כתובת להפניית לקוח לא מורשה"
              label-for="title">
            <b-form-input
                v-model="localRideAccesskeyRedirectPage"
                id="label-RedirectPage"
                class="form-control border-primary rounded-2 text-right py-4"
                placeholder="exemple: https://my_website.co.il"
                required
                type="text">
            </b-form-input>
          </b-form-group>
          <b-form-group
              id="label-title"
              class="text-right"
              label="מספר המשתמשים המורשים"
              label-for="title">
            <b-form-input
                v-model="localrideAccesskeyAuthorizedUsersCount"
                id="label-Count"
                class="form-control border-primary rounded-2 text-right py-4"
                placeholder="4"
                required
                type="text">
            </b-form-input>
            <h6 dir="rtl">0 - כמות משתמשים לא מוגבלת</h6>
          </b-form-group>
        </b-form>

        <PrimaryButton
          class="w-100 mb-5"
          @click="this.saveRideAccessKeyDetailes"
        >
          שמירה 
        </PrimaryButton>
        <SecondaryButton
          :callBack="returnToRide"
          class="w-100 mb-5"
        >
          חזרה לטיול ללא שמירה
        </SecondaryButton>
      </b-col>
    </b-row>
  </b-container>
  <AppFooter/>
</div>
</template>

<script>
import ImageBanner from "@/components/ImageBanner/ImageBanner.vue";
import PrimaryButton from "@/components/Buttons/PrimaryButton.vue";
import AppNavbar from "@/components/Template/AppNavbar.vue";
import AppFooter from "@/components/Template/AppFooter.vue";
import SecondaryButton from "@/components/Buttons/SecondaryButton.vue";
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'EditRideAccessKey',
  components: {
    AppFooter,
    PrimaryButton,
    ImageBanner,
    AppNavbar,
    SecondaryButton,
    },
  computed: {...mapGetters([  'rideInformationTitle',
                              'rideAccesskeySecretKey',
                              'rideAccesskeyRedirectPage',
                              'rideAccesskeyAuthorizedUsersCount',
                              'userId',
                              'rideAccesskeyAction',
                              'rideAccesskeyActionIsEdit',
                              'isUserSubdomainManager',
                              ]),
      
      localRideAccesskeyActionIsEdit:{
        get () {
          return this.rideAccesskeyActionIsEdit;
        },
        set (value) {
          const convertedValue = value ;
          this.$store.commit('setRideAccesskeyAction', convertedValue)
        },
      },
      localRideAccesskeySecretKey:{
        get () {
          return this.rideAccesskeySecretKey
        },
        set (value) {
          this.$store.commit('setRideAccesskeySecretKey', value )
        }
      },
      localRideAccesskeyRedirectPage:{
        get () {
          return this.rideAccesskeyRedirectPage
        },
        set (value) {
          this.$store.commit('setRideAccesskeyRedirectPage', value )
        }
      },
      localrideAccesskeyAuthorizedUsersCount:{
        get () {
          return this.rideAccesskeyAuthorizedUsersCount
        },
        set (value) {
          this.$store.commit('setRideAccesskeyAuthorizedUsersCount', value )
        }
      },
    },
    methods:
    {
    ...mapActions([ 'updateRideAccessKey',
                    'ReturnToSingleRide',
                    'getRideAccessKey',
                    ] ),
    saveRideAccessKeyDetailes() {
      const rideIdFromUrl = this.$route.params.ride_key;
      this.updateRideAccessKey( rideIdFromUrl )
      this.$router.push('/single_ride_with_map/' + rideIdFromUrl);
    },
    returnToRide() {
      const rideIdFromUrl = this.$route.params.ride_key;
      if (rideIdFromUrl) {
        this.$router.push('/single_ride_with_map/' + rideIdFromUrl);
      }

    },
    copyLink() {
      const link = this.rideAccesskeySecretKey;
      navigator.clipboard
        .writeText(link)
        .then(() => {
          this.$bvToast.toast('הקישור הועתק בהצלחה!', {
            title: 'העתקת קישור',
            variant: 'success',
            solid: true,
          });
        })
        .catch((err) => {
          console.error('Failed to copy link: ', err);
          this.$bvToast.toast('שגיאה בהעתקת הקישור', {
            title: 'שגיאה',
            variant: 'danger',
            solid: true,
          });
        });
    },
  },
  created() {
    const rideIdFromUrl = this.$route.params.ride_key;
    if (rideIdFromUrl) {
      this.getRideAccessKey( rideIdFromUrl )
    }
  },
};
</script>


<style scoped>
.custom-height {
  height: 50px; /* Adjust to desired height */
  line-height: 1.5; /* Ensures proper text alignment */
}

#checkbox-1 {
  display: flex;
  align-items: center;
}
#checkbox-2 {
  display: flex;
  align-items: center;
}

.form-control{
  background-color: white !important;
}
.dropdown-menu {
  margin-top: 6px;
  width: 100% !important;
}


</style>